import React, { useState, useEffect, useContext } from 'react';
import './ComponentBuilder.css';
import GameComponent from '../game/component/GameComponent';
import EditorPanel from '../game/editor/EditorPanel';
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';
import withAuthorization from "../components/withAuthorization";
import uuid from 'react-native-uuid';
import draftToHtml from 'draftjs-to-html';
import {
    Item,
    Submenu,
    MenuProvider,
    Menu
} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { firebase } from "../firebase";
import * as routes from "../constants/routes";
import { ComponentTypes } from '../game/component/ComponentTypes';
import ReactGA from 'react-ga';
import TemplateComponentsTable from '../game/project/collection/TemplateComponentsTable';
import TabbedSelection from '../game/components/TabbedSelection';
import { hasPermissionToEdit } from '../game/project/permissions';
import { StorageContext } from '../Storage/StorageContext';

export function ComponentBuilder({ projectId, collectionId, componentId, collectionItemType, authUser, history }) {

    const defaultComponent = {
        name: '',
        quantity: 1,
        scale: 100,
        orientation: 'portrait',
        editSection: null,
        cardStyle: 'american',
        width: 56,
        height: 87,
        backgroundSize: 'cover',
        cardBorder: 'solid',
        borderSize: 2,
        borderRadius: 0,
        borderColor: '#000000',
        backgroundColor: '#ffffff',
        backgroundImage: null,
        sectionsById: {},
        templateVariables: [],
        templateData: [],
        tabSelection: 'Front',
        canEdit: true,
        collectionItemType: collectionItemType
    }

    ReactGA.pageview(`/projects/${projectId}/collections/${collectionId}/components/${componentId}`)

    const [canEdit, setCanEdit] = useState(true);
    const [editTemplateRow, setEditTemplateRow] = useState();
    const [component, setComponent] = useState(defaultComponent);
    const [template, setTemplate] = useState();
    const [templateData, setTemplateData] = useState([]);

    const [editorState, setEditorState] = useState();
    const updateComponentAttribute = (props) => {
        setComponent(prevState => {
            return { ...prevState, ...props }
        })
    };
    const [collectionBack, setCollectionBack] = useState();
    const [editorSelectedColumns, setEditorSelectedColumns] = useState();
    const [editSection, setEditSection] = useState();
    const [tabSelection, setTabSelection] = useState('Front');
    const [collectionData, setCollectionData] = useState();
    const [scale, setScale] = useState(100);
    const [imagesToRemove, setImagesToRemove] = useState([]);
    const contextFiles = useContext(StorageContext);

    useEffect(() => {
        const firestore = firebase.firestore;
        if (collectionId) {
            firestore.collection('projects')
                .doc(projectId)
                .collection('collections')
                .doc(collectionId)
                .get().then(snapshot => {
                    if (snapshot.exists) {
                        var collection = snapshot.data()
                        const type = collection.type === 'tokens' ? ComponentTypes.token.name : ComponentTypes.card.name;
                        const collectionData = Object.assign({}, collection.data, { type, type }, { style: collection.style })
                        updateComponentAttribute(collectionData);
                        setCollectionData(collection.data);
                    }
                }).catch(err => console.log("error occurred trying to retrieve collection: ", collectionId, err))
        }

        if (componentId !== 'new') {
            var componentRef = firestore.collection('projects')
                .doc(projectId)
                .collection('collections')
                .doc(collectionId)
                .collection('components')
                .doc(componentId)
                .get().then(snapshot => {
                    if (snapshot.exists) {
                        var sectionsByIdToShow = Object.values(snapshot.data().sectionsById).reduce(function (map, obj) {
                            const contentState = obj.rawContent;
                            const editorState = contentState ? EditorState.createWithContent(convertFromRaw(contentState)) : EditorState.createEmpty();
                            map[obj.id] = Object.assign({}, { ...obj }, { editorState: editorState })
                            return map;
                        }, {});

                        const parsedUniqueVariables = JSON.stringify(sectionsByIdToShow).match(/\$\{(.*?)\}/g);
                        var templateVariables = [];
                        if (parsedUniqueVariables) {
                            templateVariables = [... new Set(parsedUniqueVariables
                                .map(match => RegExp('\{(.*?)\}', 'g').exec(match)[1]))];
                        }

                        var updatedBack;
                        if (snapshot.data().back) {
                            updatedBack = Object.assign({}, { ...snapshot.data().back })
                            if (snapshot.data().back && snapshot.data().back.sectionsById) {
                                var backSections = Object.values(snapshot.data().back.sectionsById).reduce(function (map, obj) {
                                    const contentState = obj.rawContent;
                                    const editorState = contentState ? EditorState.createWithContent(convertFromRaw(contentState)) : EditorState.createEmpty();
                                    map[obj.id] = Object.assign({}, { ...obj }, { editorState: editorState })
                                    return map;
                                }, {});

                                const parsedUniqueBackVariables = JSON.stringify(backSections).match(/\$\{(.*?)\}/g);
                                if (parsedUniqueBackVariables) {
                                    templateVariables = templateVariables.concat([... new Set(parsedUniqueBackVariables
                                        .map(match => RegExp('\{(.*?)\}', 'g').exec(match)[1]))]);
                                }

                                updatedBack.sectionsById = backSections;
                            }
                        }

                        updateComponentAttribute({ ...snapshot.data(), sectionsById: sectionsByIdToShow, templateVariables, back: updatedBack });
                        setTemplateData(snapshot.data().templateData);
                    }
                }
                ).catch(
                    err => console.log("error occurred trying to retrieve component: ", componentId, err)
                );
        }

        firestore.collection('projects')
            .doc(projectId)
            .collection('collections')
            .doc(collectionId)
            .collection('components')
            .where('collectionItemType', '==', 'back')
            .get()
            .then(snapshots => {
                snapshots.forEach(back => {
                    setCollectionBack(back.data());
                })
            })
            .catch(e => console.log("issue with getting collection back", e));

        firestore.collection('projects')
            .doc(projectId)
            .get()
            .then(project => {
                updateComponentAttribute('canEdit', hasPermissionToEdit(project.data(), authUser))
            })
            .catch(e => console.log("issue with getting project back", e));

        if (!contextFiles.files[projectId]) {
            console.log("Files are not populated for component to load file dropdown");
            contextFiles.populateFilesForProject(projectId);
        }
    }, [componentId, projectId, collectionId]);

    const addRectangleSection = (event) => {
        addSection(event, 'rectangle', '0')
    }

    const addOvalSection = (event) => {
        addSection(event, 'oval', '50')
    }

    const addBack = () => {
        updateComponentAttribute({
            back: {
                ...defaultComponent,
                ...collectionData,
                collectionItemType: collectionItemType
            }
        });
    }

    const addSection = (event, shape, borderRadius, sectionToCopy) => {
        const sectionsById = tabSelection === 'Front' ? component.sectionsById : component.back.sectionsById;
        const keys = Object.keys(sectionsById).map(Number);
        const maxId = keys.length > 0 ? Math.max(...keys) : 0;
        const id = keys.length == 0 ? 0 : maxId + 1;

        const newSection = sectionToCopy ? Object.assign({}, sectionToCopy, { id: id }, { index: keys.length }, {}) : {
            id: id,
            name: 'section',
            shape: shape ? shape : 'rectangle',
            editorState: EditorState.createEmpty(),
            content: null,
            x: event ? event.event.layerX : component.width * .25 * 3.779,
            y: event ? event.event.layerY : component.height * .25 * 3.779,
            width: component.width / 2 * 3.779,
            height: component.height / 2 * 3.779,
            border: 'none',
            borderColor: '#000000',
            borderRadius: borderRadius ? borderRadius : '0',
            borderSize: '3',
            index: keys.length
        };

        if (tabSelection === 'Front') {
            updateComponentAttribute({
                sectionsById: Object.assign({}, sectionsById, {
                    [id]: Object.assign({}, sectionsById[id], newSection)})
            });
        } else {
            const updatedSectionsById = Object.assign({},
                { ...sectionsById, [id]: Object.assign({}, component.back.sectionsById[id], newSection) }
            )
            updateComponentAttribute({
                back: { ...component.back, sectionsById: updatedSectionsById }
            });
        }

        setEditSection(newSection);
        setEditorState(newSection.editorState);
    };

    const handleEditTemplatedRow = (rowId) => {
        const row = templateData.filter(row => row.id === rowId)[0];
        var mergedSectionsById;
        var mergedBackSectionsById = {};

        //if switching between template rows when editing
        if (editTemplateRow) {

            mergedSectionsById = Object.values(template.sectionsById).reduce(function (map, obj) {
                const templateRowSection = row.sectionsById && row.sectionsById[obj.id] ? row.sectionsById[obj.id] : null;
                map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                return map;
            }, {});

            updateComponentAttribute({ ...row, sectionsById: mergedSectionsById });

            if (template.back) {
                mergedBackSectionsById = Object.values(template.back.sectionsById).reduce(function (map, obj) {
                    const templateRowSection = row.back && row.back.sectionsById && row.back.sectionsById[obj.id] ? row.back.sectionsById[obj.id] : null;
                    map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                    return map;
                }, {});

                updateComponentAttribute({ back: Object.assign({}, template.back, row.back, { sectionsById: mergedBackSectionsById }) });
            }
        } else {
            setTemplate(component);

            mergedSectionsById = Object.values(component.sectionsById).reduce(function (map, obj) {
                const templateRowSection = row.sectionsById && row.sectionsById[obj.id] ? row.sectionsById[obj.id] : null;
                const editorState = (templateRowSection && templateRowSection.rawContent) ?
                    EditorState.createWithContent(convertFromRaw(templateRowSection.rawContent)) :
                    obj.editorState;
                map[obj.id] = { ...obj, ...templateRowSection, editorState: editorState }
                return map;
            }, {});
            updateComponentAttribute({ ...row, sectionsById: mergedSectionsById });

            if (component.back) {
                mergedBackSectionsById = Object.values(component.back.sectionsById).reduce(function (map, obj) {
                    const templateRowSection = row.back && row.back.sectionsById && row.back.sectionsById[obj.id] ? row.back.sectionsById[obj.id] : null;
                    const editorState = (templateRowSection && templateRowSection.rawContent) ?
                        EditorState.createWithContent(convertFromRaw(templateRowSection.rawContent)) :
                        obj.editorState;
                    map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection, editorState: editorState })
                    return map;
                }, {});
                updateComponentAttribute({ back: Object.assign({}, component.back, row.back, { sectionsById: mergedBackSectionsById }) });
            }
        }

        if (editSection && editSection.id) {
            if (tabSelection === 'Front') {
                const editorState = mergedSectionsById[editSection.id].editorState
                setEditorState(editorState);
            } else {
                const editorState = mergedBackSectionsById[editSection.id].editorState
                setEditorState(editorState);
            }
        }

        setEditTemplateRow(rowId);
    }

    const handleFinishUpdatingTemplateRow = () => {
        setEditTemplateRow(null);
        updateComponentAttribute({
            ...template, sectionsById:
                template.sectionsById
        });
        setTemplateData(templateData);
    }

    const handleCellUpdated = (id, index, column, value) => {
        const updatedList = templateData.map(row => {
            if (row.id === id) {
                if (column.includes('variables.')) {
                    return Object.assign({}, row,
                        {
                            variables: Object.assign({},
                                templateData.filter(row => row.id === id)[0].variables,
                                { [column.replace('variables.', '')]: value })
                        })
                } else {
                    return Object.assign({}, row, { [column]: value })
                }
            } else {
                return row
            }
        });

        setTemplateData(updatedList);
    }

    const handleDeleteTemplatedRow = (id) => {
        const updatedList = templateData.filter(row => row.id !== id);
        setTemplateData(updatedList);
    }

    const handleZoom = (event) => {

        setScale(event.target.value);
    }

    const onDeselect = () => {
        setEditSection(null);
    };

    const onCardUpdated = (props) => {
        if (editTemplateRow) {
            if (tabSelection === 'Front') {
                const updatedTemplateData = templateData.map(row => {
                    if (row.id === editTemplateRow) {
                        return Object.assign({}, row, props);
                    } else {
                        return row;
                    }
                })
                setTemplateData(updatedTemplateData);
            } else {
                const updatedTemplateData = templateData.map(row => {
                    if (row.id === editTemplateRow) {
                        return Object.assign({}, row, { back: Object.assign({}, row.back, props) });
                    } else {
                        return row;
                    }
                })
                setTemplateData(updatedTemplateData);
            }
        }

        if (tabSelection === 'Back') {
            updateComponentAttribute(...component.back, ...props)
        } else {
            updateComponentAttribute(props);
        }
    };

    const handleCopySection = (event, sectionId) => {
        if(tabSelection === 'Front'){
            addSection(event, null, null, component.sectionsById[sectionId]);
        }else{
            addSection(event, null, null, component.back.sectionsById[sectionId]);  
        }

        if (event) {
            event.stopPropagation();
        }
    }

    const deleteSection = (sectionId) => {

        if(tabSelection === 'Back'){
            var updatedSections = removeKey(component.back.sectionsById, sectionId);
            var sectionsArray = Object.values(updatedSections);
            sectionsArray.sort((b, a) => b.index - a.index)
            //need to reorder the indexes and assign object back to updatedSections
            sectionsArray.forEach((element, index, array) => updatedSections[element.id] = array[index]);
            updateComponentAttribute({back:
                {...component.back, sectionsById: updatedSections }});
        }else{
            var updatedSections = removeKey(component.sectionsById, sectionId);
            var sectionsArray = Object.values(updatedSections);
            sectionsArray.sort((b, a) => b.index - a.index)
            //need to reorder the indexes and assign object back to updatedSections
            sectionsArray.forEach((element, index, array) => updatedSections[element.id] = array[index]);
            updateComponentAttribute({ sectionsById: updatedSections });
        }
    };

    const deleteImage = (uuid) => {
        var imagesToRemove = imagesToRemove ? imagesToRemove : [];
        if (uuid) {
            setImagesToRemove(imagesToRemove.concat(uuid));
        }
    }

    const removeKey = (obj, deleteKey) => {
        let clone = Object.assign({}, obj);
        delete clone[deleteKey];
        return clone;
    }

    const onSectionResize = (id, props) => {
        updateComponentAttribute({
            sectionsById: Object.assign({}, component.sectionsById, {
                [id]: Object.assign({}, component.sectionsById[id], props)
            })
        })
    };

    const onSectionUpdated = (id, props) => {
        if (tabSelection === 'Front') {
            updateComponentAttribute({
                sectionsById: Object.assign({}, component.sectionsById, {
                    [id]: Object.assign({}, component.sectionsById[id], props)
                })
            })

            if (editTemplateRow) {
                const updatedTemplateData = templateData.map(row => {
                    if (row.id === editTemplateRow) {
                        const existingSection = row.sectionsById && row.sectionsById[id] ? row.sectionsById[id] : null;
                        const updatedSectionsById = Object.assign({}, row.sectionsById, {
                            [id]: Object.assign({}, existingSection, props)
                        })
                        return Object.assign({}, row, { sectionsById: updatedSectionsById });
                    } else {
                        return row;
                    }
                })
                setTemplateData(updatedTemplateData);
            }
        } else {

            if (editTemplateRow) {
                const updatedTemplateData = templateData.map(row => {
                    if (row.id === editTemplateRow) {

                        //did this because row.back and row.back.sectionsById can be null
                        const copiedSectionsById = Object.assign({}, row.back && row.back.sectionsById ? row.back.sectionsById : {});
                        const existingSection = row.back && row.back.sectionsById && row.back.sectionsById[id] ? row.back.sectionsById[id] : null
                        const updatedSectionsById = Object.assign({}, copiedSectionsById, {
                            [id]: Object.assign({}, existingSection, props)
                        })
                        return Object.assign({}, row, { back: Object.assign({}, row.back, { sectionsById: updatedSectionsById }) });
                    } else {
                        return row;
                    }
                })
                setTemplateData(updatedTemplateData);
            }

            const updatedSectionsById = Object.assign({}, { ...component.back.sectionsById, [id]: Object.assign({}, component.back.sectionsById[id], props) })
            updateComponentAttribute({
                back: { ...component.back, sectionsById: updatedSectionsById }
            });
        }
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const htmlContent = draftToHtml(
            rawContent);

        setEditSection(Object.assign({}, editSection, { htmlContent }, { rawContent }));
        onSectionUpdated(editSection.id,
            { editorState: editorState, htmlContent, rawContent })
    };

    const onSectionSelect = (id) => {

        const sectionToEdit = tabSelection === 'Front'?component.sectionsById[id]:component.back.sectionsById[id];
        
        if (sectionToEdit && editSection &&
            sectionToEdit.id === editSection.id) {
                setEditSection(null);
        } else {
            if (sectionToEdit) {
                setEditSection(sectionToEdit);
                setEditorState(sectionToEdit.editorState);
            }
        }
    };

    const onChangeSectionOrder = (from, to) => {
        if(tabSelection === 'Back'){
            var updatedSections = array_move(Object.values(component.back.sectionsById).sort((a, b) => a.index - b.index), from, to);
            var newSectionsById = Object.assign({});
            updatedSections.forEach(function callback(currentValue, index, array) {
                newSectionsById[currentValue.id] = Object.assign({}, currentValue, { index: index })
            });
            updateComponentAttribute({back:{...component.back, sectionsById: newSectionsById }});
        }else{
            var updatedSections = array_move(Object.values(component.sectionsById).sort((a, b) => a.index - b.index), from, to);
            var newSectionsById = Object.assign({});
            updatedSections.forEach(function callback(currentValue, index, array) {
                newSectionsById[currentValue.id] = Object.assign({}, currentValue, { index: index })
            });
            updateComponentAttribute({ sectionsById: newSectionsById });
        }
    };

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const handleAddTemplateDataRow = () => {
        const id = firebase.firestore.collection('projects').doc().id
        setTemplateData(templateData.concat(Object.assign({ id: id, quantity: 1 })))
    }

    const save = () => {
        const firestore = firebase.firestore;
        var cardsRef = firestore.collection('projects')
            .doc(projectId)
            .collection('collections')
            .doc(collectionId)
            .collection('components');

        if (componentId === 'new') {
            ReactGA.event({ category: 'COMPONENT', action: 'CREATE' });
        } else {
            ReactGA.event({ category: 'COMPONENT', action: 'SAVE' });
        }

        var componentRef = (componentId === 'new') ? cardsRef.doc() : cardsRef.doc(componentId);
        var sections = Object.assign({}, component.sectionsById);

        var backToSave = component.back ? Object.assign({}, { ...component.back }) : null;
        if (component.back && component.back.sectionsById) {
            var backSections = Object.assign({}, component.back.sectionsById);
            Object.keys(backSections).forEach(id => delete backSections[id].editorState);
            backToSave.sectionsById = backSections;
        } else {
            delete component.back;
        }

        Object.keys(sections).forEach(id => delete sections[id].editorState);
        Object.values(templateData).forEach(
            data => {
                if (data.sectionsById) {
                    Object.keys(data.sectionsById)
                        .forEach(id => delete data.sectionsById[id].editorState)
                }

                if (data.back && data.back.sectionsById) {
                    Object.keys(data.back.sectionsById)
                        .forEach(id => delete data.back.sectionsById[id].editorState)
                } else if (!data.back && data.back) {
                    delete data.back;
                }
            }
        );

        let { name,
            quantity,
            orientation,
            cardStyle,
            width,
            height,
            cardBorder,
            borderSize,
            borderRadius,
            borderColor,
            backgroundSize,
            backgroundImage,
            backgroundColor,
            collectionItemType
        } = component;
        const cardToSave = Object.assign({},
            {
                name,
                quantity,
                orientation,
                cardStyle,
                width,
                height,
                cardBorder,
                borderSize,
                borderRadius,
                borderColor,
                backgroundSize,
                backgroundImage,
                backgroundColor,
                collectionItemType,
                templateData,
                back: backToSave
            },
            { sectionsById: { ...sections } },
        );

        componentRef.set(cardToSave)
            .then(() => history.push(`${routes.PROJECTS}/${projectId}?set=${collectionId}&focus=${componentRef.id}`)
            ).catch(
                err => console.log("error occurred trying to save card", err)
            );
    };

    const getCardBackgroundImagePromise = (componentRef, backgroundImageCopy) => {
        if (backgroundImageCopy && !backgroundImageCopy.uploaded) {
            return uploadImage(`projects/${projectId}/cards/${componentRef.id}/images`,
                backgroundImageCopy.url,
                backgroundImageCopy.name,
                (url, imageUuid) => {
                    backgroundImageCopy.url = url;
                    backgroundImageCopy.uuid = imageUuid;
                    backgroundImageCopy.uploaded = true;
                }
            )
        }
    }

    const uploadImage = (path, imageData, name, assignUrl) => {
        var imageBlob = dataURItoBlob(imageData);
        var imageUuid = uuid.v4();
        var fileRef = firebase.storage.ref(`${path}`).child(imageUuid);
        var uploadTask = fileRef.put(imageBlob);
        return uploadTask.then(
            () => fileRef.getDownloadURL().catch(() => console.log("error occured getting download url"))
        ).then(
            downloadURL => assignUrl(downloadURL, imageUuid)
        ).catch(
            () => console.log("error occurred uploading image")
        )
    }

    const removeImage = (path, uuid) => {
        var fileRef = firebase.storage.ref(`${path}`).child(uuid);
        return fileRef.delete();
    }

    const removeCustomBack = () => {
        updateComponentAttribute({ back: null });
    }

    const dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    const cancel = () => {
        ReactGA.event({ category: 'COMPONENT', action: 'CANCEL' });
        history.push(`${routes.PROJECTS}/${projectId}?set=${collectionId}`);
    };

    const handleTabSelection = (tab) => {
        setTabSelection(tab);
        setEditSection(null);
    }

    const MyAwesomeMenu = () => (
        <Menu id='editor_menu_id'>
            <Submenu label="Add">
                <Item onClick={addRectangleSection}>Rectangle</Item>
                <Item onClick={addOvalSection}>Oval</Item>
            </Submenu>
        </Menu>
    );
    const tabs = {
        Front: {},
        Back: {}
    };

    const renderGameComponent = () => {
        const borderRadiusType = component.type === ComponentTypes.token.name ? '%' : 'px';
        if (tabSelection === 'Back') {
            const backData = component.back ? component.back : collectionBack;
            if (backData) {
                return <GameComponent
                    data-testid="game-component"
                    {...backData}
                    editSection={editSection}
                    sections={backData.sectionsById}
                    onSectionSelect={onSectionSelect}
                    onSectionUpdated={onSectionUpdated}
                    onClick={onDeselect}
                    borderRadiusType={borderRadiusType}
                    canEdit={canEdit} />
            } else {
                return <div>No back exists for this collection</div>
            }
        } else {
            return <GameComponent
                data-testid="game-component"
                name={component.name}
                quantity={component.quantity}
                orientation={component.orientation}
                width={component.width}
                height={component.height}
                cardBorder={component.cardBorder}
                borderColor={component.borderColor}
                borderSize={component.borderSize}
                borderRadius={component.borderRadius}
                backgroundSize={component.backgroundSize}
                backgroundColor={component.backgroundColor}
                editSection={editSection}
                sections={component.sectionsById}
                onSectionSelect={onSectionSelect}
                backgroundImage={component.backgroundImage}
                onSectionUpdated={onSectionUpdated}
                onClick={onDeselect}
                borderRadiusType={borderRadiusType}
                canEdit={canEdit} />
        }
    }

    const renderEditorPanel = () => {
        if (tabSelection === 'Front') {
            return <EditorPanel
                name={component.name}
                quantity={component.quantity}
                componentType={component.type}
                componentStyle={component.style}
                orientation={component.orientation}
                width={component.width}
                height={component.height}
                cardBorder={component.cardBorder}
                backgroundColor={component.backgroundColor}
                borderColor={component.borderColor}
                borderSize={component.borderSize}
                borderRadius={component.borderRadius}
                editorState={editorState}
                editSection={editSection}
                sections={component.sectionsById}
                onCardUpdated={onCardUpdated}
                onEditorStateChange={onEditorStateChange}
                onSectionUpdated={onSectionUpdated}
                backgroundImage={component.backgroundImage}
                backgroundSize={component.backgroundSize}
                onSectionSelect={onSectionSelect}
                onAddNewSection={addSection}
                deleteSection={deleteSection}
                deleteImage={deleteImage}
                projectId={projectId}
                collectionId={collectionId}
                componentId={componentId}
                imageFolders={contextFiles.files[projectId]}
                changeSectionOrder={onChangeSectionOrder}
                collectionItemType={collectionItemType}
                onCopySection={handleCopySection}
                editTemplateRow={editTemplateRow ? true : false}
                back={handleFinishUpdatingTemplateRow}
                save={save}
                cancel={cancel}
                canEdit={canEdit} />
        } else {
            if (component.back) {
                return <EditorPanel
                    {...component.back}
                    editorState={editorState}
                    editSection={editSection}
                    componentType={component.type}
                    sections={component.back.sectionsById}
                    onCardUpdated={onCardUpdated}
                    onEditorStateChange={onEditorStateChange}
                    onSectionSelect={onSectionSelect}
                    onSectionUpdated={onSectionUpdated}
                    onCopySection={handleCopySection}
                    onAddNewSection={addSection}
                    deleteSection={deleteSection}
                    deleteImage={deleteImage}
                    projectId={projectId}
                    imageFolders={contextFiles.files[projectId]}
                    changeSectionOrder={onChangeSectionOrder}
                    collectionItemType={collectionItemType}
                    removeCustomBack={removeCustomBack}
                    editTemplateRow={editTemplateRow ? true : false}
                    back={handleFinishUpdatingTemplateRow}
                    save={save}
                    cancel={cancel}
                    canEdit={canEdit}
                />
            } else {
                return <div className="editorPanelContainer">
                    <div style={{ textAlign: 'center', margin: '5px' }}>
                        Currently using back defined by set/deck
        </div>
                    <div style={{ display: 'flex' }}>
                        <button className="button customize-back-button" onClick={addBack}>Customize for this {component.type}</button>
                    </div>
                </div>;
            }
        }
    }

    const getParsedVariables = (sections) => {
        if (sections) {
            return Object.values(sections)
                .map(it =>
                    it && it.htmlContent ? JSON.stringify(it.htmlContent).match(/\$\{(.*?)\}/g) : null
                )
                .filter(it => it !== null)
        }
    }

    const renderTemplateTable = () => {
        if (collectionItemType === 'template') {

            var parsedUniqueVariables;
            if (editTemplateRow) {
                parsedUniqueVariables = getParsedVariables(template.sectionsById);
                if (template.back) {
                    parsedUniqueVariables = parsedUniqueVariables.concat(getParsedVariables(template.back.sectionsById));
                }

            } else {
                parsedUniqueVariables = getParsedVariables(component.sectionsById);
                if (component.back && component.back.sectionsById) {
                    parsedUniqueVariables = parsedUniqueVariables.concat(getParsedVariables(component.back.sectionsById));
                }
            }

            var templateVariables = [];
            if (parsedUniqueVariables && parsedUniqueVariables.length > 0) {
                templateVariables = [... new Set(parsedUniqueVariables
                    .map(match => RegExp('\{(.*?)\}', 'g').exec(match)[1]))];
            }

            return <TemplateComponentsTable
                imageFolders={contextFiles.files[projectId]}
                handleCellUpdated={handleCellUpdated}
                editorSelectedColumns={editorSelectedColumns}
                componentProps={["quantity"]}
                templateVariables={templateVariables}
                handleAddTemplateDataRow={handleAddTemplateDataRow}
                handleDeleteTemplatedRow={handleDeleteTemplatedRow}
                handleEditRow={handleEditTemplatedRow}
                template={component}
                collectionBack={collectionBack}
                data={templateData} />
        }
    }

    return component ? (
        <div className="container" >
            <div style={{
                position: 'relative',
                display: 'flex',
                flexGrow: '2',
                flexDirection: 'column'
            }}>
                <TabbedSelection style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '1em',
                    marginRight: '1em',
                    marginTop: '1em'
                }}
                    handleTabSelect={handleTabSelection}
                    tabs={tabs}
                />
                <div className="grid" style={{ maxHeight: `${component.height * 1.5}mm`, height: `${component.height * 1.5}mm`, overflow: 'hidden' }}>
                    <div className="card-wrapper" style={{ transform: `scale(${component.scale / 100})` }}>
                        <MenuProvider id="editor_menu_id">
                            {renderGameComponent()}
                        </MenuProvider>
                        <MyAwesomeMenu />
                    </div>
                    {/* <input
                        id="myRange"
                        type="range"
                        min="25"
                        max="300"
                        value={component.scale}
                        className="zoom-slider"
                        onChange={handleZoom}
                    /> */}
                </div>
            </div>

            {renderEditorPanel()}
            {renderTemplateTable()}
        </div >
    ) : <div />;
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ComponentBuilder);

