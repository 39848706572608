import React, { Component } from 'react';
import './hamburger.css';

export default class Main extends Component {

	render() {
		return (
			<div/>
		);
	}
}