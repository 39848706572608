import React, { Component } from 'react';
import GameComponent from "../../component/GameComponent";
import OverlayChecked from '../OverlayChecked';
import { TokenTypes } from '../../components/TokenTypes';

export default class ComponentSetCreationWizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'New Set'
        }
    }

    handleSetNameChange = (event) => {
        this.setState({ name: event.target.value });
    };

    handleStyleSelected = (style) => {
        if (style === this.state.selected) {
            this.setState({ selected: null })
        } else {
            this.setState({ selected: style, selectedData: this.props.types[style] })
        }
    }

    handleHeightChange = (event) => {
        this.setState({
            selectedData: Object.assign({}, this.state.selectedData, { height: event.target.value })
        });
    }

    handleWidthChange = (event) => {
        this.setState({
            selectedData: Object.assign({}, this.state.selectedData, { width: event.target.value })
        });
    }

    createCollection = () => {
        if (this.state.selectedData) {
            this.props.handleCreateCollection(this.state.name,
                this.props.componentType,
                this.state.selected,
                this.state.selectedData);
        }
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    render() {
        var openClass = this.props.open ? "open" : "close";
        return <div className={`project_editor ${openClass} expand-wrapper`}>
            <div className="project_field">
                <label>{`${this.props.collectionType} Name `}</label>
                <input className="project_input" value={this.state.name} onChange={this.handleSetNameChange} />
            </div>
            {this.renderStyles(this.props.types, this.props.stylesName)}
            <button type="button" className={`button save-button`}
                onClick={this.createCollection}>{`Create ${this.props.collectionType}`}</button>
            <button type="button" className="button cancel-button"
                style={{ right: '0px' }}
                onClick={this.handleCancel}>Cancel
        </button>
        </div>
    }

    renderStyles(types, componentSetName) {
        return <div>
            <div className="projct_field">
                <h2 style={{ textAlign: 'center' }}>{componentSetName}</h2>
            </div>
            <div className="project_fields" style={{ margin: 'auto' }}>
                {Object.keys(types).map(type =>
                    this.renderComponentItem(type)
                )}
            </div>
        </div>
    }

    calculateHeight(type) {
        if (this.state.selected === type) {
            return this.state.selectedData ? this.state.selectedData.height : this.props.types[type].height
        } else {
            return this.props.types[type].height
        }
    }

    calculateWidth(type) {
        if (this.state.selected === type) {
            return this.state.selectedData ? this.state.selectedData.width : this.props.types[type].width
        } else {
            return this.props.types[type].width
        }
    }

    renderComponentItem(type) {
        if (type.includes('custom')) {
            const customHeight = this.calculateHeight(type);
            const customWidth = this.calculateWidth(type);
            const isSelected = this.state.selected === type;
            return <div key={type} className="project_field">
                <div key={type} className="game-component-wrapper">
                    <div style={{ position: 'relative', margin: '1.2em' }}>
                        <OverlayChecked id={type}
                            isSelected={isSelected}
                            handleOnClickEvent={this.handleStyleSelected}>
                            <GameComponent
                                canEdit="true"
                                height={customHeight}
                                width={customWidth}
                                backgroundColor='ghostwhite'
                                borderRadiusType={this.props.types === TokenTypes ? '%' : 'px'}
                                borderRadius={this.props.types[type].borderRadius} />
                        </OverlayChecked>
                    </div>
                </div>
                <div style={{
                    margin: 'auto',
                    textAlign: 'center'
                }}>
                    <label>Custom</label>
                    <div>
                        <label>Size: </label>
                        <input className="project_input" style={{ width: '30px' }}
                            value={customHeight}
                            onChange={this.handleHeightChange}
                            disabled={!isSelected} />
                        <label className="project_input" style={{ margin: '.5em' }}>by</label>
                        <input className="project_input" style={{ width: '30px' }}
                            value={customWidth}
                            onChange={this.handleWidthChange}
                            disabled={!isSelected} />
                        <label className="project_input" style={{ margin: '.5em' }}>mm</label>
                    </div>
                </div>
            </div>
        } else {
            return <div key={type} className="project_field">
                <div key={type} className="game-component-wrapper">
                    <div style={{ position: 'relative', margin: '1.2em' }}>
                        <OverlayChecked id={type} isSelected={this.state.selected === type}
                            handleOnClickEvent={this.handleStyleSelected}>
                            <GameComponent height={this.props.types[type].height}
                                canEdit="true"
                                width={this.props.types[type].width}
                                borderRadius={this.props.types[type].borderRadius}
                                borderRadiusType={this.props.types === TokenTypes ? '%' : 'px'}
                                backgroundColor='ghostwhite' />
                        </OverlayChecked>
                    </div>
                </div>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    {this.props.types[type].name} ({this.props.types[type].height} x {this.props.types[type].width}) mm</div>
            </div>
        }
    }
}