export const styles = {
  base: {
    position: "absolute",
    userSelect: "none",
    MsUserSelect: "none"
  },
  top: {
    width: "100%",
    height: "10px",
    top: "-5px",
    left: "calc(50% - 4px)",
    cursor: "grab"
  },
  right: {
    width: "10px",
    height: "100%",
    top: "calc(50% - 4px)",
    right: "-5px",
    cursor: "grab"
  },
  bottom: {
    width: "100%",
    height: "10px",
    bottom: "-5px",
    left: "calc(50% - 4px)",
    cursor: "grab"
  },
  left: {
    width: "10px",
    height: "100%",
    top: "calc(50% - 4px)",
    left: "-5px",
    cursor: "grab"
  },
  topRight: {
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "-5px",
    top: "-5px",
    cursor: "grab"
  },
  bottomRight: {
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "-5px",
    bottom: "-5px",
    cursor: "grab"
  },
  bottomLeft: {
    width: "20px",
    height: "20px",
    position: "absolute",
    left: "-5px",
    bottom: "-5px",
    cursor: "grab"
  },
  topLeft: {
    width: "20px",
    height: "20px",
    position: "absolute",
    left: "-5px",
    top: "-5px",
    cursor: "grab"
  }
};