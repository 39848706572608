import React, {useEffect, useState } from 'react';
import { firebase } from "../firebase";
import StorageFolder from "./StorageFolderFC";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

export default function StorageFolders({ projectId, canEdit,
    handleImageAdded, handleFileRemoved }) {

    const [folders, setFolders] = useState([]);
    const [activeFolder, setActiveFolder] = useState("");

    useEffect(() => {
        firebase.firestore.collection('projects')
            .doc(projectId)
            .collection('folders')
            .get()
            .then(snapshots => {
                const newFolders = [];

                snapshots.forEach(
                    doc => newFolders.push(Object.assign({}, doc.data(), { id: doc.id }))
                );

                setFolders(folders.concat(newFolders));
            })
            .catch(error => console.log("Error occurred while trying to retrieve storage folders"));
    }, [projectId])

    const handleFolderNameChange = (id, name) => {
        const updatedFolders = folders.map(folder => {
            if (folder.id === id) {
                return Object.assign({}, folder, { name: name })
            } else {
                return folder
            }
        });

        setFolders(updatedFolders);
    }

    const addNewFolder = () => {
        const docId = firebase.firestore
            .collection("projects")
            .doc(projectId)
            .collection("folders")
            .doc().id;

        firebase.firestore.collection("projects")
        .doc(projectId)
        .collection("folders")
        .doc(docId)
        .set({name: 'Folder Name'})

        setFolders(folders.concat({ id: docId, name: 'Folder Name', size: 0 }))
        setActiveFolder(docId)
    }

    return (<div>
        <Accordion accordion={false}>
            {folders.map(folder => (
                <AccordionItem key={folder.id} uuid={folder.id} expanded={activeFolder === folder.id ? true : false}>
                    <AccordionItemTitle className="my_accordion__title">
                        <h3 className="accordion-collection-header">{folder.name}</h3>
                        <div className="accordion__arrow" role="presentation"></div>
                    </AccordionItemTitle>
                    <AccordionItemBody>
                        <StorageFolder projectId={projectId}
                            handleImageAdded={handleImageAdded}
                            handleFolderNameChange={handleFolderNameChange}
                            name={folder.name}
                            size={folder.size}
                            folderId={folder.id}
                            canEdit={canEdit} />
                    </AccordionItemBody>
                </AccordionItem>
            ))
            }
        </Accordion>
        <div className="project_field">
            <div className="add-collection-button"
                onClick={addNewFolder}>
                <i className="fas fa-plus add-collection-icon"></i>
            </div>
        </div>

    </div>)
}