import React, {Component} from 'react';
import Navigation from './Navigation';

export default class Header extends Component {

  render() {
    return (
        <div>
          <Navigation authUser={this.props.authUser}/>
        </div>
    );
  }
}

// export default Header;
