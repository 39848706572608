import React, { Component } from "react";
import { renderOptions } from "../../editor/helpers/Helpers";

export default class DropDownSelectorCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.cellInfo.value ? this.props.cellInfo.value : '',
        }
    }

    handleOnChange = (event) => {
        if (this.props.handleOnChange) {
            this.props.handleOnChange(this.props.cellInfo.original.id, this.props.cellInfo.index, this.props.cellInfo.column.id, event.target.value);
        }
    }

    render() {
        return (
            <select
                className="table_input-field"
                value={this.props.cellInfo.value}
                onChange={this.handleOnChange}>
                {this.props.children}
            </select>)
    }
}