import React, { Component } from 'react';
import GameComponent from '../component/GameComponent';
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import { toDataUrl } from '../editor/helpers/Helpers';

export default class Export extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportPressed: false
        }
    }

    render() {
        const updatedBackgroundImage = Object.assign({}, this.props.backgroundImage ? { ...this.props.backgroundImage } : null, { url: this.state.backgroundImageUrl })
        return <div style={{display: 'flex', flexDirection: 'column'}}>
            <div id="exportableComponent"
                // style={{ transform: 'scale(1.5)' }}
                style={{width:`${this.props.width}mm`,
                    height:`${this.props.height}mm`,
                    transform: 'scale(1)'
            }}
                >
                <GameComponent {...this.props} 
                    sections={this.props.sections}
                    backgroundImage={updatedBackgroundImage} 
                    canEdit={false}    
                />
            </div>
            <button
                onClick={() => {
                    const input = document.getElementById("exportableComponent");
                    const config = { allowTaint: true };
                    config.allowTaint = true;

                    html2canvas(input, { allowTaint: true, useCORS: true, backgroundColor: '#000000', scale: 5})
                        .then((canvas) => {
                            const imgData = canvas.toDataURL('image/png');
                            download(imgData, `test.png`, 'image/png');
                        });
                }}>
                Generate PNG
            </button>
        </div>

    }

    componentDidMount() {
        this.generateExport(this.props)
    }

    generateExport = (props) => {
        const backgroundPromise = new Promise((resolve, rejected) => {
            if (props.backgroundImage) {
                toDataUrl(props.backgroundImage.url, (result) => {
                    resolve(result);
                })
            } else {
                resolve(null)
            }
        })

        const sectionPromises = Object.keys(props.sections).map(id => {
            return new Promise((resolve, rejected) => {
                if (props.sections[id].backgroundImage) {
                    toDataUrl(props.sections[id].backgroundImage.url, (result) => {
                        resolve(Object.assign({ id: id, backgroundImage: result }));
                    })
                }
            })
        })

        Promise.all([backgroundPromise].concat(sectionPromises)).then(
            ([backgroundImageResult, sectionPromiseResult]) => {
                if (backgroundImageResult) {
                    this.setState({ backgroundImageUrl: backgroundImageResult })
                }

                if (sectionPromiseResult) {
                    // this.setState()
                    sectionPromiseResult.map(result => console.log("section result", result));
                }
            }
        ).catch(e => console.log("exception happened while getting background images", e));

    }
}