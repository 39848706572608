import React, { Component } from "react";
import "react-table/react-table.css";

export default class DeleteCell extends Component {
    constructor(props) {
        super(props);
    }

    handleOnClick = () => {
        if (this.props.handleOnClick) {
            this.props.handleOnClick(this.props.cellInfo.original.id, this.props.cellInfo.index)
        }
    }

    render() {
        return (<div className="icon-cell">
            <i className="fas fa-trash-alt delete-row-icon" onClick={this.handleOnClick} />
        </div>)
    }
}