export const TokenTypes = {
    'small-round': {
      height: 20,
      width: 20,
      borderRadius: 50,
      name: 'Small Round'
    },
    'medium-round': {
      height: 30,
      width: 30,
      borderRadius: 50,
      name: 'Medium Round'
    },
    'large-round': {
      height: 40,
      width: 40, 
      borderRadius: 50,
      name: 'Large Round'
    },
    'custom-round': {
      height: 30,
      width: 30,
      borderRadius: 50,
      name: 'Custom Round'
    },
    'small-square': {
      height: 20,
      width: 20,
      name: 'Small Square'
    },
    'medium-square': {
      height: 30,
      width: 30,
      name: 'Medium Square'
    },
    'large-square': {
      height: 40,
      width: 40,
      name: 'Large Square'
    },
    'custom-square': {
      height: 30,
      width: 30,
      name: 'Custom Square'
    }
  };