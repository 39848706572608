import React, { Component } from "react";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import "./SectionEditor.css";
import CustomEditor from "../../../components/editor/CustomEditor";
import { storage } from "../../../firebase/firebase";
import BackgroundImageSelector from "../BackgroundImageSelector";
import { isNullOrUndefined } from "util";

export default class SectionEditor extends Component {
  onSectionBackgroundColorUpdated = color => {
    this.props.onSectionUpdated(this.props.section.id, {
      backgroundColor: color.color,
      backgroundColorAlpha: color.alpha
    });
  };

  onImageSelected = (image) => {
    this.props.onSectionUpdated(this.props.section.id, { backgroundImage: image })
  };

  handleSectionNameChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      name: event.target.value
    });
  };

  handleBorderChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      border: event.target.value
    });
  };

  handleBorderSizeChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      borderSize: event.target.value
    });
  };

  handleQRCodeValueChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      qrcode: {
        ...this.props.section.qrcode,
       value: event.target.value
      }
    })
  }

  handleRotateChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      rotate: event.target.value
    });
  };

  handleShapeChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      shape: event.target.value,
      borderRadius: event.target.value === 'oval' ? '50' : '0'
    });
  }

  onEditorStateChange = event => {
    this.props.onEditorStateChange(event);
  };

  handleBorderColorChange = color => {
    this.props.onSectionUpdated(this.props.section.id, {
      borderColor: color.color,
      borderColorAlpha: color.alpha
    });
  };

  handleSvgColorChange = color => {
    this.props.onSectionUpdated(this.props.section.id, {
      svgColor: color.color,
      svgColorAlpha: color.alpha
    });
  };

  handleBorderRadiusChange = event => {
    this.props.onSectionUpdated(this.props.section.id, {
      borderRadius: event.target.value
    });
  };

  deleteBackgroundImage = () => {
    this.props.onSectionUpdated(this.props.section.id, {
      backgroundImage: null,
      svgColor: null,
      svgColorAlpha: null
    });
  };

  deleteSection = () => {
    this.props.deleteSection(this.props.section.id);
  };

  renderName() {
    return (
      <div className="editor-field">
        <label className="editor-label">Name: </label>
        <input
          className="editor-input"
          value={this.props.section.name}
          onChange={this.handleSectionNameChange}
        />
      </div>
    );
  }

  renderShape() {
    return (
      <div className="editor-field">
        <label className="editor-label">Shape: </label>
        <select
          className="editor-selector"
          id="component-shape-selector"
          value={this.props.section.shape}
          onChange={this.handleShapeChange}
        >
          <option value="rectangle">Rectangle</option>
          <option value="oval">Oval</option>
        </select>
      </div>
    );
  }

  renderQRCode(){
    const qrCodeValue = this.props.section.qrcode?this.props.section.qrcode.value:null;
    return (
      <div className="editor-field">
        <label className="editor-label">QR Code Value: </label>
        <input
          className="editor-input"
          value={qrCodeValue}
          onChange={this.handleQRCodeValueChange}
        />
      </div>
    )
  }

  renderBorder() {
    return (
      <div className="editor-field">
        <label className="editor-label">Border: </label>
        <select
          className="editor-selector"
          id="component-border-selector"
          value={this.props.section.border}
          onChange={this.handleBorderChange}
        >
          <option value="dashed">Dashed</option>
          <option value="dotted">Dotted</option>
          <option value="solid">Solid</option>
          <option value="double">Double</option>
          <option value="groove">Groove</option>
          <option value="ridge">Ridge</option>
          <option value="inset">Inset</option>
          <option value="outset">Outset</option>
          <option value="none">None</option>
        </select>
      </div>
    );
  }

  renderBorderSize() {
    if (this.props.section.border !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Size: </label>
          <input
            type="range"
            min="0"
            max="20"
            value={this.props.section.borderSize}
            className=" slidecontainer editor-slider"
            id="myRange"
            onChange={this.handleBorderSizeChange}
          />
        </div>
      );
    }
  }

  renderRotateSlider() {
    return (
      <div className="editor-field">
        <label className="editor-label">Rotate: </label>
        <input
          type="range"
          min="0"
          max="360"
          value={this.props.section.rotate}
          className=" slidecontainer editor-slider"
          id="myRange"
          onChange={this.handleRotateChange}
        />
      </div>
    );
  }

  renderBorderRadius() {
    if (this.props.section.border !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Radius: </label>
          <input
            type="range"
            min="0"
            max="50"
            value={this.props.section.borderRadius}
            className=" slidecontainer editor-slider"
            id="myRange"
            onChange={this.handleBorderRadiusChange}
          />
        </div>
      );
    }
  }

  renderBorderColor() {
    if (this.props.section.border !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Color: </label>
          <ColorPicker
            className="middle"
            color={this.props.section.borderColor}
            alpha={
              this.props.section.borderColorAlpha
                ? this.props.section.borderColorAlpha
                : 100
            }
            defaultColor="#000000"
            onChange={this.handleBorderColorChange}
          />
        </div>
      );
    }
  }

  renderSvgColor() {
    if (this.hasSvgBackgroundImage()) {
      return (
        <div className="editor-field">
          <label className="editor-label">Image Color: </label>
          <ColorPicker
            className="middle"
            color={this.props.section.svgColor}
            alpha={
              this.props.section.svgColorAlpha
                ? this.props.section.svgColorAlpha
                : 100
            }
            defaultColor="#000000"
            onChange={this.handleSvgColorChange}
          />
        </div>
      );
    }
  }

  hasSvgBackgroundImage() {
    if (this.props.section.backgroundImage && this.props.section.backgroundImage.data) {
      return true;
    } else {
      return false;
    }
  }

  renderHelpTextForTemplateVariables() {
    if (this.props.collectionItemType === 'template') {
      return <div className="help-text" dangerouslySetInnerHTML={{
        __html: `In the editor you can create variables. Simply use the format \$\{myVariableName\}. 
      You can then define the variable values in the template data table.`}} />
    }
  }

  renderEditor() {
    if (this.props.section && !this.hasSvgBackgroundImage()) {
      return (
        <React.Fragment>
          {this.renderHelpTextForTemplateVariables()}
          <CustomEditor
            editSection={this.props.section}
            editorState={this.props.editorState}
            onEditorStateChange={this.onEditorStateChange}
          />
        </React.Fragment>
      );
    }
  }

  renderBackgroundColor() {
    return (
      <div className="editor-field">
        <label className="editor-label">Background: </label>
        <ColorPicker
          className="middle"
          color={this.props.section.backgroundColor}
          alpha={
            this.props.section.backgroundColorAlpha
              ? this.props.section.backgroundColorAlpha
              : 100
          }
          defaultColor="#ffffff"
          onChange={this.onSectionBackgroundColorUpdated}
        />
      </div>
    );
  }

  handleUploadStart() { }

  handleUploadError() { }

  handleUploadSuccess = filename => {
    storage
      .ref(`projects/${this.props.projectId}/images`)
      .child(filename)
      .getDownloadURL()
      .then(url =>
        this.props.onSectionUpdated(this.props.section.id, {
          backgroundImage: { name: filename, url: url }
        })
      );
  };

  handleProgress() { }

  handleFileUpload = event => {
    var reader = new FileReader();
    this.filename = event.target.files[0].name;

    reader.onloadend = () => {
      this.props.onSectionUpdated(this.props.section.id, {
        backgroundImage: {
          url: reader.result,
          uploaded: false,
          name: this.filename
        }
      });
    };

    var dataUrl = reader.readAsDataURL(event.target.files[0]);
  };

  renderImageUploader() {
    return (<div className="editor-field">
      <label className="editor-label">Background Image: </label>
      <BackgroundImageSelector
        imageFolders={this.props.imageFolders}
        backgroundImage={this.props.section.backgroundImage}
        includeSvgs={true}
        onImageRemoved={this.deleteBackgroundImage}
        onImageSelected={this.onImageSelected}
      />
      {this.renderSvgColor()}
    </div>)
  }

  renderVariableSelector() {
    if (this.props.templateVariables && this.props.templateVariables.length > 0) {
      return (
        <div>
          <label className="editor-label">Available Deck Template Variables: </label>
          <div className="variables">
            {Object.keys(this.props.templateVariables[0]).map(variableName =>
              this.renderVariable(variableName)
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <label className="editor-label">Available Variables: </label>
          <div className="variables">No deck template variables exist.</div>
        </div>
      );
    }
  }

  renderVariable(variableName) {
    return (
      <div key={variableName} className="variable">
        <h5>{'${'}{variableName}{'}'}</h5>
      </div>
    );
  }

  renderDeleteIcon() {
    if (!this.props.editTemplateRow) {
      return <i
        className="fas fa-trash-alt trash-icon"
        onClick={this.deleteSection}
      />
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.renderName()}
        {this.renderShape()}
        {/* {this.renderRotateSlider()} */}
        {this.renderBackgroundColor()}
        {this.renderImageUploader()}
        {this.renderBorder()}
        {this.renderBorderRadius()}
        {this.renderBorderColor()}
        {this.renderBorderSize()}
        {/* {this.renderQRCode()} */}
        {this.renderEditor()}
        {this.renderDeleteIcon()}
      </div>
    );
  }
}
