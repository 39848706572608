import React, { useState, useEffect, useContext } from 'react';
import { firebase } from "../firebase";
import Dropzone from 'react-dropzone';
import './storage.css';
import OverlayMenu from '../game/project/OverlayMenu';
import { StorageContextConsumer, StorageContextProvider, StorageContext } from './StorageContext';

export default function StorageFolder({ projectId, folderId, handleFolderNameChange, canEdit, name, size }) {
    const fileContext = useContext(StorageContext);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (fileContext.files[projectId] && fileContext.files[projectId][folderId]) {

        } else {
            var folderFiles = [];
            firebase.firestore
                .collection('projects')
                .doc(projectId)
                .collection('folders')
                .doc(folderId)
                .collection('assets')
                .get()
                .then(snapshots => {
                    snapshots.forEach(doc => {
                        folderFiles.push(Object.assign({}, doc.data(), { id: doc.id }))

                    })
                    fileContext.addFolderFiles(projectId, folderId, name, size, folderFiles);
                })
                .catch(error => console.log(`error getting all assets for folder ${folderId}`, error))
        }
    }, [projectId, folderId])

    const saveFolderName = (event) => {
        if (isChanged) {
            firebase.firestore.collection('projects')
                .doc(projectId)
                .collection('folders')
                .doc(folderId)
                .set({ name: name }, { merge: true })
                .then(() => {
                    setIsChanged(false)
                })
        }
    }

    const folderNameChange = (event) => {
        handleFolderNameChange(folderId, event.target.value);
        setIsChanged(true);
    }

    const dropAccepted = (files) => {
        const fileNames = files.map(file => file.name);
        setUploadedFiles(fileNames);
        files.map(file => uploadImage(file));
    }

    const handleDeleteFile = (id) => {
        firebase.firestore
            .collection('projects')
            .doc(projectId)
            .collection('folders')
            .doc(folderId)
            .collection('assets')
            .doc(id)
            .delete()
            .then(() => {
                fileContext.deleteFileFromProjectFolder(projectId, folderId, id);
            })
            .catch(error => console.log(`error trying to delete file from database`, error));
    }

    const uploadImage = (file) => {
        if (file.type.includes('svg')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                firebase.firestore
                    .collection('projects')
                    .doc(projectId)
                    .collection('folders')
                    .doc(folderId)
                    .collection('assets')
                    .add({ name: file.name, type: file.type, data: reader.result, size: file.size })
                    .then(ref => {
                        const image = Object.assign({ id: ref.id, name: file.name, type: file.type, data: reader.result, folderId: folderId })
                        const updatedUploadedFiles = uploadedFiles.filter(uploadedFile => uploadedFile !== file.name)
                        fileContext.addFileToFolder(projectId, folderId, image);
                        setUploadedFiles(updatedUploadedFiles);
                    })
                    .catch(error => console.log("error trying to add database entry for file storage record", error))
            }
            reader.readAsText(file);
        } else {
            var fileRef = firebase.storage.ref(`projects/${projectId}/${folderId}`).child(file.name);
            var uploadTask = fileRef.put(file);
            uploadTask.then(
                () => fileRef.getDownloadURL().catch(() => console.log("error occured getting download url"))
            ).then(
                downloadURL => {
                    firebase.firestore
                        .collection('projects')
                        .doc(projectId)
                        .collection('folders')
                        .doc(folderId)
                        .collection('assets')
                        .add({ name: file.name, type: file.type, url: downloadURL, size: file.size })
                        .then(ref => {
                            const image = Object.assign({ id: ref.id, name: file.name, type: file.type, url: downloadURL, folderId: folderId })
                            const updatedUploadedFiles = uploadedFiles.filter(uploadedFile => uploadedFile !== file.name)
                            fileContext.addFileToFolder(projectId, folderId, image);
                            setUploadedFiles(updatedUploadedFiles);
                        })
                        .catch(error => console.log("error trying to add database entry for file storage record", error))
                }
            ).catch(
                () => console.log("error occurred uploading image")
            )
        }
    }

    const renderPreview = (file) => {
        if (file.type.includes('svg')) {
            return (
                <div key={file.id} className="image-preview-wrapper">

                    <div key={file.id} style={{ margin: "auto", display: 'flex', flexDirection: 'column' }}>
                        <OverlayMenu id={file.id}
                            handleDelete={handleDeleteFile}>
                            <div className="image-preview" dangerouslySetInnerHTML={{ __html: file.data }} />
                        </OverlayMenu>
                        <div style={{ margin: 'auto' }} label="fileName">{file.name}</div>
                    </div>

                </div>
            )
        } else {
            return (
                <div key={file.id} className="image-preview-wrapper">

                    <div key={file.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <OverlayMenu id={file.id}
                            handleDelete={handleDeleteFile}>
                            <div className="image-preview" style={{ backgroundImage: `url(${file.url})` }} />
                        </OverlayMenu>
                        <div style={{ wordWrap: "break-word", margin: 'auto' }} label="fileName">{file.name}</div>
                    </div>
                </div>
            )
        }
    }

    const renderDropZone = () => {
        if (canEdit) {
            const activeStyle = {
                borderStyle: 'solid',
                borderColor: '#6c6',
                backgroundColor: '#eee'
            };
            const rejectStyle = {
                borderStyle: 'solid',
                borderColor: '#c66',
                backgroundColor: '#eee'
            };

            return (
                <Dropzone accept="image/*" onDropAccepted={dropAccepted}>
                    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        let styles = {}
                        styles = isDragActive ? { ...styles, ...activeStyle } : styles
                        styles = isDragReject ? { ...styles, ...rejectStyle } : styles

                        return (
                            <div className="file-upload" {...getRootProps()} style={styles}>
                                <input {...getInputProps()} />
                                <div>
                                    Click or {isDragAccept ? 'drop' : 'drag'} files here...
                            </div>
                                {isDragReject && <div>Unsupported file type...</div>}
                            </div>
                        )
                    }}
                </Dropzone>
            )
        }
    }

     

    return (
        <React.Fragment>
            <div className="project_fields">
                <div className="project_field">
                    <label>Name </label>
                    <input className="project_input input_field" value={name}
                        onChange={folderNameChange}
                        onBlur={saveFolderName} />
                </div>
            </div>
            <div className="project_fields">
                <div className="project_field">
                    <div className="file-messages">
                        <ul>
                            {uploadedFiles ? uploadedFiles.map(file => <li key={file}>Uploading {file}...</li>) : null}
                        </ul>
                    </div>
                </div>
                {renderDropZone()}
                  {
                            fileContext.files[projectId] 
                            && fileContext.files[projectId][folderId] 
                            && fileContext.files[projectId][folderId].files? 
                                <div className="images-preview">
                                    Folder Size: {fileContext.files[projectId][folderId].size/1000/1000} MBs
                                    {fileContext.files[projectId][folderId].files.map(file => renderPreview(file))}
                                </div> : null}
            </div>
        </React.Fragment>)
}
