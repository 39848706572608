import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Main from './main/Main';
import Header from './header/Header';
import {BrowserRouter} from 'react-router-dom';
import unregister from './registerServiceWorker';
// import { slide as Menu } from 'react-burger-menu'

// ReactDOM.render(<App />, document.getElementById('root'));

// const App = () => (
// <div>
// 	<Header />
// 	{/*<Menu isOpen={ true }>*/}
// 		{/*<a id="home" className="menu-item" href="/">Home</a>*/}
// 		{/*<a id="market" className="menu-item" href="/">Market</a>*/}
// 		{/*<a id="about" className="menu-item" href="/about">About</a>*/}
// 		{/*<a id="contact" className="menu-item" href="/contact">Contact</a>*/}
// 		{/*<a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a>*/}
// 	{/*</Menu>*/}
// 	<Main />
// </div>
// )

ReactDOM.render(
	<App/>, document.getElementById('root'));
unregister();
