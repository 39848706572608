import React, { useState } from 'react';
import { firestore } from "../firebase/firebase";

export function StorageContextProvider(props) {

    const [files, setFiles] = useState({});
    const addFolderFiles = (projectId, folderId, folderName, size, folderFiles) => {
        setFiles(prevState => {
            if (prevState[projectId]) {
                return Object.assign({}, prevState, {
                    [projectId]: Object.assign({}, prevState[projectId],
                        {
                            [folderId]: {
                                name: folderName,
                                size: size,
                                files: folderFiles
                            }
                        })
                });
            } else {
                return Object.assign({}, prevState, {
                    [projectId]: {
                        [folderId]: {
                            name: folderName,
                            size, size,
                            files: folderFiles
                        }
                    }
                }
                );
            }
        })
    }
    const addFileToFolder = (projectId, folderId, file) => {
        setFiles(prevState => {
            return Object.assign({}, prevState,
                {
                    [projectId]: Object.assign({}, prevState[projectId],
                        {
                            [folderId]: {
                                files: prevState[projectId][folderId].files.concat(file),
                                name: prevState[projectId][folderId].name
                            }
                        })
                });
        })
    }
    const deleteFileFromProjectFolder = (projectId, folderId, fileId) => {
        setFiles(prevState => {
            return Object.assign({}, prevState,
                {
                    [projectId]: Object.assign({}, prevState[projectId],
                        {
                            [folderId]: {
                                files: prevState[projectId][folderId].files.filter(file => file.id !== fileId),
                                name: prevState[projectId][folderId].name
                            }
                        }
                    )
                }
            )
        })
    }

    const populateFilesForProject = (projectId) => {
        return firestore.collection('projects')
            .doc(projectId)
            .collection('folders')
            .get()
            .then(snapshots => {
                snapshots.forEach(folder => {

                    if (!files[folder.id]) {
                        firestore
                            .collection('projects')
                            .doc(projectId)
                            .collection('folders')
                            .doc(folder.id)
                            .collection('assets')
                            .get()
                            .then(snapshots => {

                                var folderFiles = [];
                                snapshots.forEach(doc => {
                                    folderFiles = folderFiles.concat(Object.assign({}, doc.data(), { id: doc.id }))
                                })

                                addFolderFiles(projectId, folder.id, folder.data().name, folder.data().size, Object.assign({}, folderFiles));
                            })
                            .catch(error => console.log(`error getting all assets for folder ${folder.id}`, error))
                    }

                })
            })
            .catch(error => console.log("Error occurred while trying to retrieve storage folders"));
    }

    return <StorageContext.Provider value={{
        files: files,
        setFiles: setFiles,
        addFolderFiles: addFolderFiles,
        addFileToFolder: addFileToFolder,
        deleteFileFromProjectFolder: deleteFileFromProjectFolder,
        populateFilesForProject: populateFilesForProject
    }}>
        {props.children}
    </StorageContext.Provider>

}

export const StorageContext = React.createContext();
export const StorageContextConsumer = StorageContext.Consumer