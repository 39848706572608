import React, { Component } from 'react';
import './Project.css';
import { Link } from 'react-router-dom';
import { DeleteModal } from '../../components/modals/DeleteVerification';

export default class OverlayMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      deleteRequested: false
    }
  }

  handleOnClickEvent = (event) => {
    this.setState({ selected: !this.state.selected });
    if (this.props.handleOnClickEvent) {
      this.props.handleOnClickEvent(this.props.id);
    }
  }

  handleOnMouseOver = (event) => {
    if (!this.state.selected) {
      this.setState({ selected: true });
      if (this.props.handleOnClickEvent) {
        this.props.handleOnClickEvent(this.props.id);
      }
    }
  }

  handleOnMouseLeave = (event) => {
    if (this.state.selected) {
      this.setState({ selected: false });
      if (this.props.handleOnClickEvent) {
        this.props.handleOnClickEvent(this.props.id);
      }
    }
  }

  handleDelete = () => {
    if (this.props.handleDelete) {
      this.props.handleDelete(this.props.id);
    }
  }

  handleCancel =() => {
    this.setState({deleteRequested: false});
  }

  deleteRequested = (e) => {
    e.stopPropagation();
    this.setState({ deleteRequested: true });
  }

  handleDuplicate = () => {
    if (this.props.handleDuplicate) {
      this.props.handleDuplicate(this.props.id);
    }
  }

  handleEdit = () => {
    if (this.props.handleEdit) {
      this.props.handleEdit(this.props.id);
    }
  }

  render() {
    return <React.Fragment>
      <div onMouseEnter={this.handleOnMouseOver}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={this.handleOnClickEvent}
      >
        {this.displayMenu()}
        {this.props.children}
      </div>
    </React.Fragment>
  }

  renderLink() {
    if (this.props.editSelectedPath) {
      return (
        <Link className="edit-link" key={this.props.id} to={this.props.editSelectedPath}>
          <i className="fas fa-edit edit-icon"
            style={{ margin: 'auto' }} />
        </Link>
      )
    }
  }

  renderDuplicateIcon() {
    if (this.props.handleDuplicate) {
      return <i className="far fa-copy duplicate-card-icon"
        onClick={this.handleDuplicate}
        style={{ margin: 'auto' }} />
    }
  }

  renderDeleteModal = () => {
    if(this.state.deleteRequested){
      return <DeleteModal 
        className="delete-modal"
        handleConfirm={this.handleDelete}
        handleCancel={this.handleCancel}
      />
    }
  }

  renderDeleteLink = () => {
    return <i className="fas fa-trash-alt trash-card-icon"
    style={{ margin: 'auto' }}
    onClick={this.deleteRequested} />
  }

  renderButtons = () => {
    if(!this.state.deleteRequested){
      return <React.Fragment>
        {this.renderDeleteLink()}
        {this.renderLink()}
        {this.renderDuplicateIcon()}
      </React.Fragment>
    }
  }

  displayMenu() {
    if (this.state.selected) {
      return <React.Fragment>
        <div className="center-transform"
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: 'rgba(200, 200, 200, 0.5)',
            borderRadius: this.props.borderRadius,
            zIndex: '100',
            fontSize: '2.5em'
          }}>
          {this.renderDeleteModal()}
          {this.renderButtons()}
        </div>
      </React.Fragment>
    }
  }
}