import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export function DeleteProject(props) {
  if (props.canDelete) {
    return (
      <div className="delete-project" onClick={props.deleteProject}>
        <i className="fas fa-trash-alt project-trash-icon" />
      </div>
    )
  }else{
    return null;
  }
}

export default class ProjectThumbNail extends Component {

  deleteProject = () => {
    this.props.deleteProject(this.props.id);
  };

  render() {
    return <React.Fragment
      key={this.props.id}>
      <div className="project" key={this.props.id}>
        <Link className="project_title" to={{ pathname: `${routes.PROJECTS}/${this.props.id}` }}>
          {this.props.children}
        </Link>
        <div className="project-square-contents">
          <DeleteProject canDelete={this.props.deleteProject} deleteProject={this.deleteProject}/>
          {/* <Carousel showArrows={true}
              // width={"50%"}
              showStatus={false}
              showThumbs={false}>
              {this.renderCarousel()}
            </Carousel> */}
        </div>
      </div>
    </React.Fragment>
  }

  renderCarousel() {
    if (this.props.project.images) {
      return this.props.project.images.map(
        image =>
          <div>
            <img style={{
              maxHeight: '250px',
              objectFit: 'contain'
            }} src={image} />
          </div>)
    }
  }
}