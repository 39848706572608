import React, { Component } from 'react';
import { firebase } from "../../../firebase";
import '../Project.css';
import { Link } from 'react-router-dom';
import '../../../css/react-accordion.css';
import GameComponent from "../../component/GameComponent";
import OverlayMenu from "../OverlayMenu";
import AddOverlay from "../AddOverlay";
import * as routes from "../../../constants/routes";
import download from 'downloadjs';
import ReactGA from 'react-ga';
import TemplateComponentsTable from './TemplateComponentsTable';

export default class ComponentCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: {},
      cardBack: null,
      newVariableName: '',
      name: ''
    }
  }

  handleCollectionNameChange = (event) => {
    // this.setState({name: event.target.value});
    this.props.handleCollectionNameChange(this.props.collectionId, { name: event.target.value });
  };

  saveName = (event) => {
    firebase.firestore.collection('projects')
      .doc(this.props.projectId)
      .collection('collections')
      .doc(this.props.collectionId)
      .update({ name: this.props.collection.name });
  }

  handleGameComponentSelected = (id) => {
    this.setState({ componentSelected: id });
  }

  handleDeleteGameComponent = (id) => {
    ReactGA.event({ category: 'COMPONENT', action: 'DELETE' });
    const firestore = firebase.firestore;
    firestore.collection('projects')
      .doc(this.props.projectId)
      .collection('collections')
      .doc(this.props.collectionId)
      .collection('components')
      .doc(id)
      .delete().catch(
        err => {
          console.log('Error getting documents', err);
        }
      );

    var updatedComponents = this.removeKey(this.state.components, id);
    this.setState({
      components: updatedComponents
    })
  }

  removeKey(obj, deleteKey) {
    let clone = Object.assign({}, obj);
    delete clone[deleteKey];
    return clone;
  }

  handleEditGameComponent = (id) => {
    const component = this.state.components[id];
    this.props.history.push(`${routes.PROJECTS}/${this.props.project}/collections/${this.props.collectionId}/components/${id}?collectionItemType=${component.collectionItemType}`);
  }

  handleDeleteCollection = () => {
    this.props.handleDeleteCollection(this.props.collectionId, this.props.collectionType)
  }

  render() {
    const componentBackId = Object.keys(this.state.components).filter(id => this.state.components[id].collectionItemType === 'back')[0];
    const templates = Object.keys(this.state.components).filter(id => this.state.components[id].collectionItemType === 'template');
    const individuals = Object.keys(this.state.components).filter(id => this.state.components[id].collectionItemType === 'individual');
    const borderRadiusType = this.props.collectionType === 'tokens' ? '%' : 'px';

    return <div className="deck">
      <div className="project_field">
        <label>Name </label>
        <input className="project_input input_field" value={this.props.collection.name}
          onChange={this.handleCollectionNameChange}
          onBlur={this.saveName} />
      </div>
      <div className="project_fields">
        <div>
          <div className="project_field">
            <label style={{ textAlign: 'center' }}>{this.upperCaseFirstLetter(this.props.collectionType)} Back</label>
          </div>
          {this.renderCollectionItem(componentBackId, this.state.components[componentBackId], borderRadiusType, 'back')}
        </div>
        <div>
          <div className="project_field">
            <label>Templates</label>
          </div>
          {this.renderComponents(templates, borderRadiusType, 'template')}
        </div>
      </div>
      <div className="project_fields">
        <div className="width_100">
          <div className="project_field">
            <label className="collection-label">{this.upperCaseFirstLetter(this.props.collectionType)}</label>
            <Link key="printer-view-deck" to={{ pathname: `/projects/${this.props.projectId}/collections/${this.props.collectionId}/print` }} 
               onClick={(event) => {event.preventDefault(); window.open(`/projects/${this.props.projectId}/collections/${this.props.collectionId}/print`);}}> 

               <button className="print-button button" >
                Print {this.upperCaseFirstLetter(this.props.collectionType)}
                <i className="fas fa-print printer-icon" />
              </button> 
           </Link>
            {/* <Link>
              <i className="fas fa-print printer-icon"
               onClick={(event) => {
                event.preventDefault();
                firebase.functions.useFunctionsEmulator('http://localhost:5001');
                console.log("current user", firebase.auth.currentUser.xa);
                var printCollectionFunction = firebase.functions.httpsCallable('printCollectionOnCall');
                printCollectionFunction({projectId: this.props.projectId, collectionId: this.props.collectionId, authorization: firebase.auth.currentUser.xa })
                .then(result => {
                  download(`data:applicatoin/pdf;base64,${result.data}`, `test.pdf`, 'application/pdf');
                }).catch(err => {console.log("error ocrred", err)});
              }}
              >
              </i>
            </Link> */}
          </div>
          {this.renderComponents(individuals, borderRadiusType, 'individual')}
          <button className="button delete-button" onClick={this.handleDeleteCollection}>Delete {this.upperCaseFirstLetter(this.props.collectionType)}</button>
        </div>
      </div>
    </div>
  }

  upperCaseFirstLetter(word) {
    return word?word.charAt(0).toUpperCase() + word.slice(1):null;
  }

  componentDidMount() {
    const firestore = firebase.firestore;
    const collectionPromise = firestore.collection('projects')
      .doc(this.props.projectId)
      .collection('collections')
      .doc(this.props.collectionId)
      .get().catch(
        err => {
          console.log('Error getting collection data', err)
        }
      );

    const componentsPromise = firestore.collection('projects')
      .doc(this.props.projectId)
      .collection('collections')
      .doc(this.props.collectionId)
      .collection('components')
      .get().catch(
        err => {
          console.log('Error getting documents', err);
        }
      );

    Promise.all([componentsPromise, collectionPromise]).then(
      ([snapshot, collectionSnapshot]) => {
        if (snapshot) {
          snapshot.forEach(doc =>
            // output.push(doc.data())
            this.setState({
              components: Object.assign({}, this.state.components, {
                [doc.id]: Object.assign({}, this.state.components[doc.id],
                  doc.data())
              })
            })
          );
        }

        if (collectionSnapshot) {
          this.setState({ ...collectionSnapshot.data() })
        }
      }).catch();
  }

  componentDidUpdate() {
    if (this.props.focusRef && this.props.focusRef.current && this.props.focusRef.current) {
      this.props.focusRef.current.focus();
    }
  }

  renderCollectionItem(id, template, borderRadiusType, collectionItemType) {
    if (template) {
      return <div className="component_list">
        <div className="game-component-wrapper">
          <div style={{ position: 'relative' }}>
            <OverlayMenu id={id}
              editSelectedPath={{ pathname: `/projects/${this.props.projectId}/collections/${this.props.collectionId}/components/${id}`, search: `?collectionItemType=${collectionItemType}` }}
              borderRadius={`${template.borderRadius}${borderRadiusType}`}
              isSelected={id === this.state.selectedCard ? true : false}
              handleOnClickEvent={this.handleGameComponentSelected}
              handleEdit={this.handleEditGameComponent}
              handleDelete={this.handleDeleteGameComponent}
              handleDuplicate={this.handleDuplicateGameComponent}>
              <GameComponent key={id} {...this.state.components[id]}
                sections={template.sectionsById}
                borderRadiusType={borderRadiusType} />
            </OverlayMenu>
          </div>
        </div>
      </div>
    } else {
      return <div className="component_list">
        <div className="game-component-wrapper">
          <Link key="template" to={{ pathname: `/projects/${this.props.projectId}/collections/${this.props.collectionId}/components/new`, search: `?collectionItemType=${collectionItemType}` }} >
            <div className="create-card">
              <AddOverlay borderRadius={`${this.props.collectionData.borderRadius}${borderRadiusType}`}>
                <GameComponent width={this.props.collectionData.width}
                  height={this.props.collectionData.height}
                  backgroundColor='ghostwhite'
                  cardBorder='solid'
                  borderSize='2'
                  borderColor='gray'
                  borderRadius={this.props.collectionData.borderRadius}
                  borderRadiusType={borderRadiusType} >
                </GameComponent>
              </AddOverlay>
            </div>
          </Link>
        </div>
      </div>
    }
  }

  renderComponents(componentKeys, borderRadiusType, collectionItemType) {
    return (
      <div className="component_list">
        <div className="game-component-wrapper">
          <Link key="cards" to={{ pathname: `/projects/${this.props.projectId}/collections/${this.props.collectionId}/components/new`, search: `?collectionItemType=${collectionItemType}` }} >

            <div className="create-card">
              <AddOverlay borderRadius={`${this.props.collectionData.borderRadius}${borderRadiusType}`}>
                <GameComponent width={this.props.collectionData.width}
                  height={this.props.collectionData.height}
                  backgroundColor='ghostwhite'
                  cardBorder='solid'
                  borderSize='2'
                  borderColor='gray'
                  borderRadiusType={borderRadiusType}
                  borderRadius={this.props.collectionData.borderRadius}>
                </GameComponent>
              </AddOverlay>
            </div>
          </Link>
        </div>
        {componentKeys.map(id =>
          <div key={id} className="game-component-wrapper">
            <div style={{ position: 'relative' }}>
              <OverlayMenu id={id}
                editSelectedPath={{ pathname: `/projects/${this.props.projectId}/collections/${this.props.collectionId}/components/${id}`, search: `?collectionItemType=${collectionItemType}` }}
                borderRadius={`${this.state.components[id].borderRadius}${borderRadiusType}`}
                isSelected={id === this.state.selectedComponent ? true : false}
                handleOnClickEvent={this.handleGameComponentSelected}
                handleEdit={this.handleEditGameComponent}
                handleDelete={this.handleDeleteGameComponent}
                handleDuplicate={this.handleDuplicateGameComponent}>
                <GameComponent key={id}
                  reff={id === this.props.focusId ? this.props.focusRef : null}
                  {...this.state.components[id]}
                  sections={this.state.components[id].sectionsById}
                  borderRadiusType={borderRadiusType} />
              </OverlayMenu>
            </div>
          </div>
        )
        }
      </div>
    )
  }
}