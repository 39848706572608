import React, { Component } from 'react';
import withAuthorization from "../../../components/withAuthorization";
import { firebase } from "../../../firebase";
import '../Project.css';
import GameComponent from "../../component/GameComponent";
import html2canvas from "html2canvas";
import PrintButton from "../../print/PrintButton"
import ReactGA from 'react-ga';
import './PrinterView.css';
import { cpus } from 'os';
import { mergeTemplateAndTemplatedData } from '../../components/TemplateHelper';

class PrinterView extends Component {

  constructor(props) {
    super(props);
    ReactGA.pageview(`/projects/${this.props.projectId}/collections/${this.props.collectionId}/print`)
    this.firestore = firebase.firestore;
    this.state = {
      components: []
    }
  }

  render() {
    return this.renderCollections()
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  renderPage(pageData) {
    var gridColumns = `${pageData.width}mm `.repeat(pageData.horizontalComponentCount);
    var gridRows = `${pageData.height}mm `.repeat(pageData.verticalComponentCount);
    const updatedPageData = Object.assign({}, { ...pageData, gridColumns, gridRows });
    return (
      <React.Fragment>
        {this.renderPageFront(updatedPageData)}
        {this.renderPageBack(updatedPageData)}
      </React.Fragment>
    )
  }

  renderPageFront(pageData) {
    return <div key={`chunk-${this.getRandomInt(1000)}`}
      id="printerView" style={{
        display: 'grid',
        gridGap: '1mm',
        gridTemplateColumns: pageData.gridColumns,
        gridTemplateRows: pageData.gridRows,
        justifyContent: 'center',
        alignContent: 'center',
      }}>
      {pageData.components
        .filter(component => component.collectionItemType === 'individual')
        .map(component =>
          <div key={`${component.id}-${this.getRandomInt(1000)}`}
            className="printer-page-item">
            <GameComponent {...component}
              borderRadiusType={pageData.collectionType === 'tokens' ? '%' : 'px'}
              printerView={true}
              sections={component.sectionsById} />
          </div>
        )}
      <div style={{ pageBreakAfter: 'always' }} />
    </div>
  }

  renderPageBack(pageData) {
    const hasBacks = pageData.collectionBack || pageData.components
    .filter(component => component.collectionItemType === 'individual' && 
      component.back).length > 0;

    if(hasBacks){
      return <div key={`chunk-${this.getRandomInt(1000)}`}
        id={"printerView"} style={{
          display: 'grid',
          gridGap: '1mm',
          gridTemplateColumns: pageData.gridColumns,
          gridTemplateRows: pageData.gridRows,
          justifyContent: 'center',
          alignContent: 'center',
          direction: 'rtl'
        }}>
        {pageData.components
          .filter(component => component.collectionItemType === 'individual')
          .map(component => {
            const back = component.back?component.back:pageData.collectionBack;
            return <div key={`${component.id}-${this.getRandomInt(1000)}-back`}
                      className="printer-page-item">
                      <GameComponent {...back}
                        borderRadiusType={pageData.collectionType === 'tokens' ? '%' : 'px'}
                        printerView={true}
                        sections={back?back.sectionsById:[]} />
                    </div>
          }
          )}
        <div style={{ pageBreakAfter: 'always' }} />
      </div>
    }
  }

  renderCollections() {
    var height = 0;
    var width = 0;
    if (this.state.components && this.state.components.length > 0) {
      height = this.state.components[0].height;
      width = this.state.components[0].width;
    }

    const collectionBack = this.state.components.filter(component => component.collectionItemType === 'back')[0];

    // const pageSize = {width: 215.9, height: 279.4}
    const pageSize = { width: 203, height: 275.4 }
    const numberAcross = Math.floor(pageSize.width / width);
    const numberDown = Math.floor(pageSize.height / height);
    const qualifyingComponents = this.state.components.filter(component => component.collectionItemType === 'individual')
    var chunks = this.chunkArray(qualifyingComponents, numberAcross * numberDown);
    const collectionType = this.state.collectionData ? this.state.collectionData.type : 'cards';
    const pageData = Object.assign({}, { horizontalComponentCount: numberAcross, verticalComponentCount: numberDown, width, height, collectionBack, collectionType: collectionType });
    return <div>
      {/* <PrintButton id={"pages"} label={"Generat PDF"} /> */}
      <div id="pages" style={{ backgroundColor: 'white' }}>
        {chunks.map(components => this.renderPage(Object.assign({}, { ...pageData, components: components })))}
      </div>
    </div >
  }

  chunkArray(myArray, chunk_size) {
    var results = [];

    while (myArray.length) {
      results.push(myArray.splice(0, chunk_size));
    }

    return results;
  }

  makeRepeated = (arr, repeats) =>
    [].concat(...Array.from({ length: repeats }, () => arr));

  componentDidMount() {
    const firestore = firebase.firestore;
    var components = [];

    const collectionPromise = firestore.collection("projects")
      .doc(this.props.projectId)
      .collection("collections")
      .doc(this.props.collectionId)
      .get().catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    const componentsPromise = firestore.collection("projects")
      .doc(this.props.projectId)
      .collection("collections")
      .doc(this.props.collectionId)
      .collection("components")
      .get().catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    Promise.all([componentsPromise, collectionPromise]).then(
      ([snapshot, collectionSnapshot]) => {
        if (snapshot) {
          snapshot.forEach(doc => {
            const quantity = doc.data().quantity;
            components = components.concat(this.makeRepeated([doc.data()], quantity));
          })
        }

        if (collectionSnapshot) {
          this.setState({ collectionData: collectionSnapshot.data() })
        }
      }
    ).then(() => {
      const templateArray = components.filter(component => component.collectionItemType === 'template');
      if (templateArray.length > 0) {
        templateArray.map(template => {
          if (template.templateData && template.templateData.length > 0) {
            template.templateData.map(templateRow => {
              const mergedData = mergeTemplateAndTemplatedData(template, templateRow);
              components = components.concat(this.makeRepeated([mergedData], mergedData.quantity));
            })
          }
        }
        )
        this.setState({ components: components })
      } else {
        this.setState({ components: components })
      }
    }).catch();
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(PrinterView);