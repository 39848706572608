import React, { Component } from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import { auth, firestore } from '../firebase';
import * as routes from '../constants/routes';
import ReactGA from 'react-ga';

const SignUpPage = ({ history }) =>
      <SignUpForm history={history} />

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};


class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      username,
      email,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => {
      firestore.doCreateUser(authUser.uid, username, email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        ReactGA.event({category: 'SIGN_UP', action: 'SIGN_UP', email: email, name:username});
        history.push(routes.LANDING);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
    })
    .catch(error => {
      this.setState(byPropKey('error', error));
    });

    event.preventDefault();
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
        passwordOne !== passwordTwo ||
        passwordOne === '' ||
        email === '' ||
        username === '';

    return (
        <form className="form" onSubmit={this.onSubmit}>
          <input 
              className="form-item input_field form_text"
              value={username}
              onChange={event => this.setState(byPropKey('username', event.target.value))}
              type="text"
              placeholder="Full Name"
          />
          <input
              className="form-item input_field form_text"
              value={email}
              onChange={event => this.setState(byPropKey('email', event.target.value))}
              type="text"
              placeholder="Email Address"
          />
          <input
              className="form-item input_field form_text"
              value={passwordOne}
              onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
              type="password"
              placeholder="Password"
          />
          <input
              className="form-item input_field form_text"
              value={passwordTwo}
              onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
              type="password"
              placeholder="Confirm Password"
          />
          <button 
            className="form-item button form_text"
            disabled={isInvalid} type="submit">
             Sign Up
          </button>

          { error && <p>{error.message}</p> }
        </form>
    );
  }
}


export default withRouter(SignUpPage);

const SignUpLink = () =>
    <p className="form-item center form_text">
      Don't have an account?
      {' '}
      <Link to={routes.SIGN_UP}>Sign Up</Link>
    </p>

export {
  SignUpForm,
  SignUpLink,
};