import React, { Component } from "react";
import "react-table/react-table.css";

export default class PreviewCell extends Component {
    constructor(props) {
        super(props);
    }

    handleOnClick = () =>{
        if(this.props.handleOnClick){
            this.props.handleOnClick(this.props.cellInfo)
        }
    }

    render() {
        return (<div className="icon-cell">
            <i className="far fa-image preview-icon" onClick={this.handleOnClick}/>
        </div>)
    }
}