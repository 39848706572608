import React, { Component } from 'react';
import './GameComponent.css';
import Section from './section/Section';

export default class GameComponent extends Component {

	constructor(props) {
		super(props);
	}

	onSectionSelect = (id) => {
		this.props.onSectionSelect(id);
	};

	onSectionUpdated = (id, props) => {
		if (this.props.canEdit) {
			this.props.onSectionUpdated(id, props);
		}
	};

	calculateSvgImage = () =>{
		if(this.backgroundImage && this.backgroundImage.data){
			return this.backgroundImage.data;
		}
	}

	render() {
		const sections = this.props.sections ? this.renderSections(Object.values(this.props.sections)) : null;
		var backgroundImageUrl = this.calculateImageUrl();
		var calculatedHeight = this.props.orientation === 'landscape' ? this.props.width : this.props.height;
		var calculatedWidth = this.props.orientation === 'landscape' ? this.props.height : this.props.width;
		var boxShadow = this.props.printerView ? 'none' : '6px 6px #888888';
		return (<div className="component" 
			data-testid="game-component"
			crossOrigin="Anonymous"
			id="component" 
			tabIndex="0"
			ref={this.props.reff}
			style={{
				overflow: this.props.canEdit?'visable':'hidden',
				pageBreakInside: 'avoid',
				backgroundColor: this.props.backgroundColor,
				borderWidth: `${this.props.borderSize}px`,
				borderColor: `${this.props.borderColor}`,
				borderStyle: `${this.props.cardBorder}`,
				backgroundSize: `${this.props.backgroundSize?this.props.backgroundSize:'cover'}`,
				backgroundRepeat: 'no-repeat',
    			backgroundPosition: 'center',
				backgroundImage: backgroundImageUrl,
				borderRadius: `${this.props.borderRadius}${this.props.borderRadiusType ? this.props.borderRadiusType : 'px'}`,
				height: `${calculatedHeight}mm`,
				minHeight: `${calculatedHeight}mm`,
				width: `${calculatedWidth}mm`,
				minWidth: `${calculatedWidth}mm`,
				boxShadow: `${boxShadow}`,
				// transform: `scale(${this.props.scale?this.props.scale: '1'})`,
				margin: `${this.props.margin?this.props.margin:'auto'}`
			}} 
			// dangerouslySetInnerHTML={{ __html: this.calculateSvgImage() }}
			>
			{this.props.children}
			{sections}
		</div>
		);
	}

	renderSections(sections) {
		const { editSection = {} } = this.props;
		var zIndex = 0;
		return sections.sort((a,b) => a.index - b.index)
			.map(section => (
			<Section key={`section-${section.id}`}
				canEdit={this.props.canEdit}
				section={section}
				isSelected={editSection && section.id === editSection.id?true:false}
				onSectionSelect={this.onSectionSelect}
				zIndex={zIndex++}
				onSectionUpdated={this.onSectionUpdated} />));
	}

	calculateImageUrl() {
		if (this.props.backgroundImage) {
			return 'url(' + this.props.backgroundImage.url + ')';
		}
		return 'none';
	}
}