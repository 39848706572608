import React, { Component } from 'react';
import '../table/table.css';
import Table from '../table/Table';
import GameComponent from '../../component/GameComponent';
import { ComponentTypes } from '../../component/ComponentTypes';

export default class TemplateComponentsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleCellUpdated = (id, index, column, value) => {
        this.props.handleCellUpdated(id, index, column, value);
    };

    handlePreviewRow = (cellInfo) => {
        this.setState({ previewId: cellInfo.index })
    }

    handleEditRow = (id) => {
        this.props.handleEditRow(id)
    }

    handlePreviewClose = () => {
        this.setState({ previewId: null })
    }

    addNewRow = () => {
        this.props.handleAddTemplateDataRow();
    }

    handleDeleteVariableDataRow = (id) => {
        this.props.handleDeleteTemplatedRow(id);
    }

    handleDeleteBackgroundImage = (id) => {
        this.props.handleCellUpdated(id, null, 'backgroundImage', null);
    }

    removeKey(obj, deleteKey) {
        let clone = Object.assign({}, obj);
        delete clone[deleteKey];
        return clone;
    }

    render() {
        const columns = []
            // .concat(this.props.componentProps.map(prop => Object.assign({Header: prop, accessor: prop})))
            .concat(this.props.templateVariables.map(variable => Object.assign({ Header: variable, accessor: `variables.${variable}` })));
        const data = this.props.data ? Object.keys(this.props.data).map(id => Object.assign({}, id, this.props.data[id])) : [];
        return (
            <div className="template__components-table">
                {this.renderPreview()}
                <div>
                    <Table
                        tableData={data}
                        imageFolders={this.props.imageFolders}
                        handleEditRow={this.handleEditRow}
                        handleDeleteVariableDataRow={this.handleDeleteVariableDataRow}
                        handleDeleteBackgroundImage={this.handleDeleteBackgroundImage}
                        handleCellUpdated={this.handleCellUpdated}
                        handlePreviewRow={this.handlePreviewRow}
                        columns={columns}
                    />
                    <div className="project_field">
                        <div className="add-template-component-button"
                            onClick={this.addNewRow}>
                            <i className="fas fa-plus add-template-component-icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBackPreview(backPreviewComponent) {
        const borderRadiusType = this.props.template.type === ComponentTypes.token.name ? '%' : 'px';

        if (backPreviewComponent) {
            return (
                <React.Fragment>
                    <div className="preview-text">Back</div>
                    <GameComponent
                        {...backPreviewComponent}
                        canEdit={false}
                        editSection={null}
                        borderRadiusType={borderRadiusType}
                        backgroundImage={backPreviewComponent.backgroundImage}
                        sections={backPreviewComponent.sectionsById} />
                </React.Fragment>)
        } else {
            return <div className="preview-text">No Back</div>
        }
    }

    renderPreview() {
        if (this.state.previewId !== null && this.state.previewId >= 0) {
            const previewData = this.props.data[this.state.previewId];
            const mergedSectionsById = Object.values(this.props.template.sectionsById).reduce(function (map, obj) {
                const templateRowSection = previewData.sectionsById && previewData.sectionsById[obj.id] ? previewData.sectionsById[obj.id] : null;
                map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                return map;
            }, {});

            const mergedBackSectionsById = this.props.template.back && this.props.template.back.sectionsById ? 
                Object.values(this.props.template.back.sectionsById).reduce(function (map, obj) {
                    const templateRowSection = previewData.back && previewData.back.sectionsById && previewData.back.sectionsById[obj.id] ? previewData.back.sectionsById[obj.id] : null;
                    map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                    return map;
                }, {}) : null;

            const borderRadiusType = this.props.template.type === ComponentTypes.token.name ? '%' : 'px';
            const mergedBack = this.props.template.back ? Object.assign({}, this.props.template.back, previewData.back, 
                { sectionsById: mergedBackSectionsById }, 
                { borderRadiusType: borderRadiusType }) : null;

            var templateJson = JSON.stringify(Object.assign({}, this.props.template, { ...previewData }, { borderRadiusType: borderRadiusType },
                { sectionsById: mergedSectionsById }, { back: mergedBack }));
            if (previewData.variables) {
                Object.keys(previewData.variables).forEach(variable =>
                    templateJson = templateJson.split(`\$\{${variable}\}`).join(previewData.variables[variable] ? previewData.variables[variable] : "")
                )
            }
            const previewRowObject = JSON.parse(templateJson);
            const backPreviewComponent = previewRowObject.back ? previewRowObject.back : this.props.collectionBack;

            return <div className="preview-component">
                <i className="fas fa-times close-preview-icon " onClick={this.handlePreviewClose} />
                <div className="preview-text">Preview</div>
                <div className="preview-wrapper-front-back">
                    <div className="preview-wrapper">
                        <div className="preview-text">Front</div>
                        <GameComponent
                            {...previewRowObject}
                            canEdit={false}
                            editSection={null}
                            backgroundImage={previewData.backgroundImage}
                            sections={previewRowObject.sectionsById} />
                    </div>
                    <div className="preview-wrapper">
                        {this.renderBackPreview(backPreviewComponent)}
                    </div>
                </div>
            </div>
        }
    }
}
