import React, { Component, useState } from 'react';
import { Switch, Route } from 'react-router-dom'
import ComponentBuilder from './ComponentBuilder/ComponentBuilderFC';
import * as routes from './constants/routes';
import SignUpPage from './header/SignUp';
import SignInPage from './header/SignIn';
import PasswordForgetPage from './header/PasswordForget';
import HomePage from './Screens/Home';
import About from './Screens/About';
import AccountPage from './components/Account';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './header/Navigation'
import withAuthentication from './components/withAuthentication';
import MyGames from './game/MyGames';
import Projects from './game/project/Projects';
import Project from './game/project/ProjectFC';
import PrinterView from './game/project/print/PrinterView';
import './App.css';
import ReactGA from 'react-ga';
import { Privacy } from './Screens/Privacy';
import { Terms } from './Screens/Terms';
import ErrorBoundary from './components/ErrorBoundary';
import { StorageContextProvider, StorageContext } from './Storage/StorageContext';
import { CardPreview } from './Screens/CardPreview';
import withAuthorization from './components/withAuthorization';
import querysearch from 'stringquery';

ReactGA.initialize('UA-91766869-1')

const reload = () => window.location.reload();

const App = () => {
  return (
    <StorageContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path={routes.CARD_TEMPLATE} component={({ match }) =>
            <Page component={() =>
              <ComponentBuilder {...match.params} />}
              includeHeader />} />
          <Route exact path={routes.CARD_EXPORT} component={({ match, location }) =>
              {
                var queryParams = querysearch(location.search);
                return <CardPreview {...match.params} {...queryParams} />
              }
            } />
          <Route exact path={routes.LANDING} component={() => <Page component={() => <HomePage />} includeHeader />} />
          <Route exact path={routes.ABOUT} component={() => <Page component={() => <About />} includeHeader />} />
          <Route exact path={routes.SIGN_UP} component={() => <Page component={() => <SignUpPage />} includeHeader />} />
          <Route exact path={routes.SIGN_IN} component={() => <Page component={() => <SignInPage />} includeHeader />} />
          <Route exact path={routes.PASSWORD_FORGET} component={() => <Page component={() => <PasswordForgetPage />} includeHeader />} />
          {/* <Route exact path={routes.HOME} component={() => <Page component={() => <HomePage />} includeHeader />} /> */}
          <Route exact path={routes.ACCOUNT} component={() => <Page component={() => <AccountPage />} includeHeader />} />
          <Route exact path={routes.MY_GAMES} component={() => <MyGames />} />
          <Route path='/privacy' component={() => <Privacy />} />
          <Route path="/terms" component={() => <Terms />} />
          <Route exact path={routes.PROJECTS} component={() => <Page component={() => <Projects />} includeHeader />} />
          <Route exact path={routes.DECK_PRINT_VIEW} component={({ match }) =>
            <PrinterPage component={() =>
              <PrinterView {...match.params} />
            } />} />
          <Route exact path={routes.PROJECT_VIEW} component={({ match }) =>
            <Page component={() =>
              <Project storageContext={StorageContext}
                {...match.params} />}
              includeHeader />} />
        </Switch>
      </BrowserRouter>
    </StorageContextProvider>
  )
}

const PrinterPage = ({ component: Component }) => (
  <React.Fragment>
    <ErrorBoundary>
      {<Component />}
    </ErrorBoundary>
  </React.Fragment>
);

const Page = ({ component: Component, includeHeader, includeFooter = true }) => (
  <div className="page">
    {includeHeader && <div className="header">
      <a className="logo-url" href="/">
        <img className="logo" src='/LogoFull.png' />
      </a>
      <div className="menu">
        <Navigation />
      </div>
    </div>}
    <div className="main">
      <ErrorBoundary>
        {<Component />}
      </ErrorBoundary>
    </div>
    {includeFooter &&
      <div className="footer">
        © 2020 Board Game Haven LLC.
        <div className="footer_links">
          <div className="footer_link"><a rel="html" type="html" href="/terms">Terms</a></div>
          <div className="footer_link"><a rel="html" type="html" href="/privacy">Privacy</a></div>
        </div>
        <div className="follow-us">
          <div className="follow-text">follow us on: </div>
          <a href="https://twitter.com/boardgamehaven">
            <i className="fab fa-twitter twitter-icon" />
          </a>
          <a href="https://www.facebook.com/BoardGameHavenLLC/">
            <i className="fab fa-facebook facebook-icon"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCI6QfwcA5CvQIq7CZpPQtcw/">
            <i className="fab fa-youtube youtube-icon"></i>
          </a>
        </div>
      </div>}
  </div>
);

export default withAuthentication(App);