import React, { Component } from 'react';

import ReactGA from 'react-ga';

export default class About extends Component {
  constructor(props) {
    super(props);   
     ReactGA.pageview('/about');
    this.state = {
    };
  }

  render() {
    return (
        <div style={{textAlign: 'center', 
            lineHeight: '2em',
            fontSize: '1.5em',
            fontFamily: 'serif',
            padding: '0 1em'}}>
          <h2 style={{margin: 'auto', paddingTop: '2em'}}>About</h2>
          <img style={{height: '7em', margin: '.5em', float: 'left', borderRadius: '10pt'}} src='./images/profilePicture.jpeg'/>
          <div>
            Hi, my name is Jonathan Borre.  I live in Southeastern Wisconsin and love to code and play board games.  
            I originally wanted to create my own board game and realized that there was not very many tools for creating 
            quick prototypes.  I created Board Game Haven to try to challenge myself to create such a tool 
            and as a means to improve my web programming knowledge and experience.  After coding most of the site, I realized 
            I was having more fun creating this site rather than designing a board game.  I hope to help new game designers be able 
            to create their content with very little ease and flush out ideas before investing too much money or effort.  I hope that
            at some point this site will be useful to even seasoned game designers prototyping their ideas as well.
            If you have any feedback on the website, I would greately appreciate it.  
            You can contact me at contact@boardgamehaven.com or jonathan.borre@boardgamehaven.com.
          </div>
        </div>
    );
  }
}