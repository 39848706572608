export function mergeTemplateAndTemplatedData(template, templatedRow) {

    if (template && templatedRow) {
        const mergedSectionsById = Object.values(template.sectionsById).reduce(function (map, obj) {
            const templateRowSection = templatedRow.sectionsById && templatedRow.sectionsById[obj.id] ? templatedRow.sectionsById[obj.id] : null;
            map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
            return map;
        }, {});

        
        var mergedBackSectionsById = null;
        var mergedBack = null;
        
        if(template.back){
            mergedBackSectionsById = template.back && template.back.sectionsById ? Object.values(template.back.sectionsById).reduce(function (map, obj) {
                const templateRowSection = templatedRow.back && templatedRow.back.sectionsById && templatedRow.back.sectionsById[obj.id] ? templatedRow.back.sectionsById[obj.id] : null;
                map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                return map;
            }, {}) : null;

            mergedBack = template.back ? Object.assign({}, template.back, templatedRow.back, 
                { sectionsById: mergedBackSectionsById }) : null;
        }

        const mergedData = Object.assign({}, {...template}, {...templatedRow}, {sectionsById: mergedSectionsById}, {back: mergedBack})

        var mergedJson = JSON.stringify(mergedData);
        if (templatedRow.variables) {
            Object.keys(templatedRow.variables).forEach(variable =>
                mergedJson = mergedJson.split(`\$\{${variable}\}`).join(templatedRow.variables[variable])
            )
        }

        return Object.assign({}, JSON.parse(mergedJson),
            { collectionItemType: 'individual' });
    }
}