import React, { Component } from "react";
import { PhotoshopPicker } from 'react-color';
import reactCSS from 'reactcss'

export default class ColorPicker extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
        // this.props.onChange('color', color.hex);
    };

    handleCancel = () => {
        this.props.onChange('color', this.state.originalColor);
        this.handleClick();
    }

    handleAccept = (color) => {
        this.props.onChange('color', this.state.color);
        this.handleClick();
    }

    componentDidMount() {
        this.setState({originalColor: this.props.currentState.color});
        this.setState({ color: this.props.currentState.color? this.props.currentState.color: '#000000'})
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    width: '22px',
                    height: '22px',
                    borderRadius: '2px',
                    background: `${this.props.currentState.color ? this.props.currentState.color : 'black'}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '204',
                    transform: 'translate(-50%, -50%)'
                },
                cover: {
                    // position: 'fixed',
                    // top: '0px',
                    // right: '0px',
                    // bottom: '0px',
                    // left: '0px',
                },
            },
        });

        return (<div>
            <div style={styles.swatch} onClick={this.handleClick}>
                <div style={styles.color} />
            </div>
            {this.state.displayColorPicker ? <div style={styles.popover}>
                <div style={styles.cover} onClick={this.handleClose} />
                <PhotoshopPicker color={this.state.color}
                    onChange={this.handleChange}
                    onCancel={this.handleCancel} 
                    onAccept={this.handleAccept} />
            </div> : null}

        </div>

        )
    }
}