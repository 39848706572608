import React from 'react';

export function CopySectionButton({sectionId, onCopySection}) {

    const handleCopySection = (e) => {
        onCopySection(e, sectionId);
    }

    return <i className="copy_section far fa-copy" onClick={handleCopySection}/>

}