import React from 'react';

export function DeleteModal(props) {
    function handleConfirm() {
        if (props.handleConfirm) {
            props.handleConfirm();
        }
    }

    function handleCancel() {
        if (props.handleCancel) {
            props.handleCancel();
        }
    }

    return (
        <div className={props.className}
            style={props && props.style ? props.styles : null}>
            <div className="delete-modal-text">Are you sure you want to delete?</div>
            <div className="button-group">
                <button className="button confirm-modal-button" onClick={props.handleConfirm}>Confirm</button>
                <button className="button cancel-modal-button" onClick={props.handleCancel}>Cancel</button>
            </div>
        </div>
    )
}