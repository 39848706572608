import React, { Component } from 'react';
// import Tab from './Tab';

export default class TabbedSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: null
        }
    }

    handleClick = (key) => {
        if(this.props.handleTabSelect){
            this.props.handleTabSelect(key)
        }
        this.setState({selectedTab: key})
    }

    render() {
        const defaultTab = this.props.defaultTab ? this.props.defaultTab : Object.keys(this.props.tabs)[0];
        const selectedTab = this.state.selectedTab ? this.state.selectedTab : defaultTab;
        return (<div style={this.props.style}>
            {Object.keys(this.props.tabs).map(key => {
                const isSelected = key === selectedTab;
                return <Tab key={key} name={key} isSelected={isSelected} handleClick={this.handleClick}/>
            })}
        </div>)
    }
}

export function Tab(props) {

    function handleClick(e) {
        props.handleClick(props.name);
    }
    
    return <div key={props.name} className={`component-tab ${props.isSelected ? 'tab-selected' : null}`}
        onClick={handleClick}>{props.name}</div>

}