import React, { Component } from 'react';
import './Project.css';
import { Link } from 'react-router-dom';

export default class AddOverlay extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <div>
                {this.displayAdd()}
                {this.props.children}
            </div>
        </div>
    }

    displayAdd() {
        return <div className="center-transform add_overlay"
            style={{
                borderRadius: this.props.borderRadius,
                display: 'flex',
                flexDirection: 'row',
                zIndex: '2',
            }}>
            <i className="fas fa-plus"
                style={{margin: 'auto', fontSize: '2em'}} />
        </div>
    }

}