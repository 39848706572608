import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {SignUpLink} from './SignUp';
import {auth, provider} from '../firebase/firebase';
import * as routes from '../constants/routes';
import {PasswordForgetLink} from './PasswordForget';
import ReactGA from 'react-ga';

const SignInPage = ({history}) =>
    <div>
      <SignInForm history={history}/>
      <PasswordForgetLink/>
      <SignUpLink/>
    </div>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);
    ReactGA.pageview('/signIn');
    this.state = {...INITIAL_STATE};
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    
    auth.signInWithEmailAndPassword(email, password)
    .then(() => {
      ReactGA.event({category: 'SIGN_IN', action: 'SIGN_IN'});
      this.setState(() => ({...INITIAL_STATE}));
      history.push(routes.LANDING);
    })
    .catch(error => {
      this.setState(byPropKey('error', error));
    });

    event.preventDefault();
  }

  googleAuth = () => {

    const {
      history,
    } = this.props;

      auth.signInWithPopup(provider).then(function (result) {
      var token = result.credential.accessToken;
      var user = result.user;
      history.push(routes.LANDING);
    }).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
    });
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
        password === '' ||
        email === '';

    return (
        <form className="form" onSubmit={this.onSubmit}>
          <div style={{margin: 'auto', display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
          <input className="form-item input_field form_text email_field"
                 value={email}
                 onChange={event => this.setState(
                     byPropKey('email', event.target.value))}
                 type="text"
                 placeholder="Email Address"
          />
          <input className="form-item input_field form_text password_field"
                 value={password}
                 onChange={event => this.setState(
                     byPropKey('password', event.target.value))}
                 type="password"
                 placeholder="Password"
          />
          <button className="button form-item form_text submit_button" disabled={isInvalid}
                  type="submit">
            Sign In
          </button>

          <div className="form-item form_text center">
            or
          </div>

          <i className="fab fa-google-plus-square google-icon form-item form_text"
             onClick={this.googleAuth}/>

          {error && <p>{error.message}</p>}
          </div>
        </form>
    );
  }
}

export default withRouter(SignInPage);

export {
  SignInForm,
};