import React, { Component } from "react";

export default class FontOption extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <li
            style={{ fontFamily: this.props.font }}
            onClick={() => this.props.onChange(this.props.font)}
            key={this.props.font}
            className="rdw-dropdownoption-default placeholder-li"
        >{this.props.font}</li>
    }
}
