import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import ColorPicker from './ColorPicker';
import FontSelector from './FontSelector';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class CustomEditor extends Component {

  onEditorStateChange = (value) => {
    this.props.onEditorStateChange(value);
  };

  render() {
    return (
      <Editor
        editorState={this.props.editorState}
        onEditorStateChange={this.onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          colorPicker: { component: ColorPicker },
          fontFamily: { component: FontSelector}
        }}
      />
    );
  }
}