export const CardTypes = {
    american: {
      height: 87,
      width: 56,
      borderRadius: 15,
      name: 'American'
    },
    "american-mini": {
      height: 63,
      width: 41,
      borderRadius: 15,
      name: 'American Mini'
    },
    european: {
      height: 92,
      width: 59, 
      borderRadius: 15,
      name: 'European'
    },
    "european-mini": {
      height: 68,
      width: 45,
      borderRadius: 15,
      name: 'European Mini'
    }, 
    "poker": {
      height: 88.9,
      width: 63.5,
      borderRadius: 15,
      name: 'Poker'
    },
    custom: {
      height: 87,
      width: 56,
      borderRadius: 15,
      name: 'Custom'
    }
  };