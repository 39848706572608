import React, { useEffect, Fragment, useState } from 'react';
import GameComponent from '../game/component/GameComponent';
import '../game/component/GameComponent';
import { firebase } from "../firebase";
import { EditorState, convertFromRaw } from 'draft-js';
import { ComponentTypes } from '../game/component/ComponentTypes';
import withAuthorization from '../components/withAuthorization';
import ReactGA from 'react-ga';

export function CardPreview({ projectId, collectionId, componentId, rowNumber, authUser, history }) {

    const [component, setComponent] = useState();
    const [template, setTemplate] = useState();
    const [templateData, setTemplateData] = useState([]);
    const [collectionData, setCollectionData] = useState();
    const updateComponentAttribute = (props) => {
        setComponent(prevState => {
            return { ...prevState, ...props }
        })
    };
    const [editorState, setEditorState] = useState();
    const [readyToRender, setReadyToRender] = useState(false);

    useEffect(() => {
        const firestore = firebase.firestore;
        if (collectionId) {
            firestore.collection('projects')
                .doc(projectId)
                .collection('collections')
                .doc(collectionId)
                .get().then(snapshot => {
                    if (snapshot.exists) {
                        var collection = snapshot.data()
                        const type = collection.type === 'tokens' ? ComponentTypes.token.name : ComponentTypes.card.name;
                        const collectionData = Object.assign({}, collection.data, { type, type }, { style: collection.style })
                        updateComponentAttribute(collectionData);
                        setCollectionData(collection.data);
                    }
                }).catch(err => console.log("error occurred trying to retrieve collection: ", collectionId, err))
        }

        if (componentId !== 'new') {
            var componentRef = firestore.collection('projects')
                .doc(projectId)
                .collection('collections')
                .doc(collectionId)
                .collection('components')
                .doc(componentId)
                .get().then(snapshot => {
                    console.log("snapshot", snapshot.data());
                    if (snapshot.exists) {
                        var sectionsByIdToShow = Object.values(snapshot.data().sectionsById).reduce(function (map, obj) {
                            const contentState = obj.rawContent;
                            const editorState = contentState ? EditorState.createWithContent(convertFromRaw(contentState)) : EditorState.createEmpty();
                            map[obj.id] = Object.assign({}, { ...obj }, { editorState: editorState })
                            return map;
                        }, {});

                        const parsedUniqueVariables = JSON.stringify(sectionsByIdToShow).match(/\$\{(.*?)\}/g);
                        var templateVariables = [];
                        if (parsedUniqueVariables) {
                            templateVariables = [... new Set(parsedUniqueVariables
                                .map(match => RegExp('\{(.*?)\}', 'g').exec(match)[1]))];
                        }

                        updateComponentAttribute({ ...snapshot.data(), sectionsById: sectionsByIdToShow, templateVariables });
                        setTemplateData(snapshot.data().templateData);
                        setReadyToRender(true);
                    }
                }
                ).catch(
                    err => console.log("error occurred trying to retrieve component: ", componentId, err)
                );
        }
    }, [componentId, projectId, collectionId]);
    console.log("Component ", component);

    if (readyToRender) {
        console.log("templateData", templateData);
        console.log("rowNumber", rowNumber);
        const previewData = templateData[rowNumber];
        // const mergedSectionsById = Object.values(template.sectionsById).reduce(function (map, obj) {
        //     const templateRowSection = previewData.sectionsById && previewData.sectionsById[obj.id] ? previewData.sectionsById[obj.id] : null;
        //     map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
        //     return map;
        // }, {});
        var mergedSectionsById = component.sectionsById

        if(component.collectionItemType === 'template'){
            mergedSectionsById = Object.values(component.sectionsById).reduce(function (map, obj) {
                const templateRowSection = previewData.sectionsById && previewData.sectionsById[obj.id] ? previewData.sectionsById[obj.id] : null;
                map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
                return map;
            }, {});
        }

        // const mergedBackSectionsById = template.back && this.props.template.back.sectionsById ? 
        //     Object.values(this.props.template.back.sectionsById).reduce(function (map, obj) {
        //         const templateRowSection = previewData.back && previewData.back.sectionsById && previewData.back.sectionsById[obj.id] ? previewData.back.sectionsById[obj.id] : null;
        //         map[obj.id] = Object.assign({}, { ...obj, ...templateRowSection })
        //         return map;
        //     }, {}) : null;

        // const borderRadiusType = template.type === ComponentTypes.token.name ? '%' : 'px';
        const borderRadiusType = component.type === ComponentTypes.token.name ? '%' : 'px';

        // const mergedBack = this.props.template.back ? Object.assign({}, this.props.template.back, previewData.back, 
        //     { sectionsById: mergedBackSectionsById }, 
        //     { borderRadiusType: borderRadiusType }) : null;

        // var templateJson = JSON.stringify(Object.assign({}, template, { ...previewData }, { borderRadiusType: borderRadiusType },
        //     { sectionsById: mergedSectionsById }
        //     // , { back: mergedBack }
        //     ));

        var templateJson = JSON.stringify(Object.assign({}, component, { ...previewData }, { borderRadiusType: borderRadiusType },
            { sectionsById: mergedSectionsById }
            // , { back: mergedBack }
        ));
        if (previewData && previewData.variables) {
            Object.keys(previewData.variables).forEach(variable =>
                templateJson = templateJson.split(`\$\{${variable}\}`).join(previewData.variables[variable] ? previewData.variables[variable] : "")
            )
        }
        const previewRowObject = JSON.parse(templateJson);

        return (
        <div>
            <GameComponent
                {...previewRowObject}
                canEdit={false}
                editSection={null}
                backgroundImage={previewData?previewData.backgroundImage:component.backgroundImage}
                sections={previewRowObject.sectionsById}
                printerView={true}
                // scale={3}
                margin={'unset'} />
         </div>)
    } else {
        return <div />
    }

}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(CardPreview);