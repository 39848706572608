import React, { Component } from "react";
import "./EditorPanel.css";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import SectionEditor from "./sectionEditor/SectionEditor";
import { storage } from "../../firebase/firebase";
import { firebase } from "../../firebase";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import "../../css/react-accordion.css";
import { CardTypes } from "../components/CardTypes";
import { ComponentTypes } from "../component/ComponentTypes";
import { TokenTypes } from "../components/TokenTypes";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BackgroundImageSelector from "./BackgroundImageSelector";
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import { renderOptions, toDataUrl } from "./helpers/Helpers";
import Export from "../export/Export";
import { CopySectionButton } from "./CopySectionButton";
import { isCompositeComponent } from "react-dom/test-utils";
import { ExportPanel } from "./ExportPanel";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  borderColor: isDragging ? '#024693' : 'lightgray',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  ...draggableStyle,
});

export default class EditorPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportToggled: false
    }
  }

  onEditorStateChange = event => {
    this.props.onEditorStateChange(event);
  };

  onSectionUpdated = (id, props) => {
    this.props.onSectionUpdated(id, props);
  };

  onImageSelected = (image) => {
    this.props.onCardUpdated({ backgroundImage: image });
  };

  handleCardSizeChange = event => {
    const cardSize = CardTypes[event.target.value];
    this.props.onCardUpdated({ cardStyle: event.target.value, ...cardSize });
  };

  handleToggleExport = event => {
    this.setState({exportToggled: !this.state.exportToggled});
  }

  handleOrientationChange = event => {
    if (this.props.orientation !== event.target.value) {
      this.props.onCardUpdated({
        orientation: event.target.value
      });
    }
  };

  handleNameChange = event => {
    this.props.onCardUpdated({ name: event.target.value });
  }

  handleQuantityChange = event => {
    this.props.onCardUpdated({ quantity: event.target.value });
  }

  handleBackgroundSizeChange = event => {
    this.props.onCardUpdated({ backgroundSize: event.target.value });
  }

  handleWidthChange = event => {
    this.props.onCardUpdated({ width: event.target.value });
  };

  handleHeightChange = event => {
    this.props.onCardUpdated({ height: event.target.value });
  };

  handleBorderChange = event => {
    this.props.onCardUpdated({ cardBorder: event.target.value });
  };

  handleBorderSizeChange = event => {
    this.props.onCardUpdated({ borderSize: event.target.value });
  };

  handleBackgroundColorChange = event => {
    this.props.onCardUpdated({ backgroundColor: event.color });
  };

  handleBorderColorChange = event => {
    this.props.onCardUpdated({ borderColor: event.color });
  };

  handleBorderRadiusChange = event => {
    this.props.onCardUpdated({ borderRadius: event.target.value });
  };

  handleAccordionChange = event => {
    this.props.onSectionSelect(event);
  };

  handleRemoveCustomBack = event => {
    if (this.props.removeCustomBack) {
      this.props.removeCustomBack();
    }
  }

  handleCopySection = (event, sectionId) => {
    this.props.onCopySection(event, sectionId);
  }

  handleSubmit = event => {
    event.preventDefault();
  };

  deleteSection = sectionId => {
    this.props.deleteSection(sectionId);
  };

  renderName() {
    return (
      <div className="editor-field">
        <label className="editor-label">Name: </label>
        <input
          className="editor-input"
          value={this.props.name}
          onChange={this.handleNameChange}
        />
      </div>
    )
  }

  renderCardStyle() {
    if (this.props.componentType === ComponentTypes.card.name) {
      return (
        <React.Fragment>
          <div className="editor-field">
            <label className="editor-label">Card Style: </label>
            <select
              className="editor-selector"
              id="card-size-selector"
              value={this.props.componentStyle}
              onChange={this.handleCardSizeChange}
              disabled={true}>
              {
                Object.keys(CardTypes).map(cardType => <option key={cardType} value={cardType}>{CardTypes[cardType].name}</option>)}
              }
            </select>
          </div>
          {this.renderCustomSize()}
        </React.Fragment>
      );
    }
  }

  renderTokenStyles() {
    if (this.props.componentType === ComponentTypes.token.name) {
      return (
        <React.Fragment>
          <div className="editor-field">
            <label className="editor-label">Token Style: </label>
            <select
              className="editor-selector"
              id="card-size-selector"
              value={this.props.componentStyle}
              onChange={this.handleCardSizeChange}
              disabled={true}>
              {
                Object.keys(TokenTypes).map(tokenType => <option key={tokenType} value={tokenType}>{TokenTypes[tokenType].name}</option>)}
              }
          </select>
          </div>
          {this.renderCustomSize()}
        </React.Fragment>
      );
    }
  }

  renderCustomSize() {
    return (
      <div className="editor-field">
        <label className="editor-label">Size: </label>
        <input
          className="editor-input"
          value={this.props.width}
          onChange={this.handleWidthChange}
          disabled={true}
        />
        <label className="editor-label">by</label>
        <input
          className="editor-input"
          value={this.props.height}
          onChange={this.handleHeightChange}
          disabled={true}
        />
      </div>
    );
  }

  renderBorder() {
    return (
      <div className="editor-field">
        <label className="editor-label">Border: </label>
        <select
          className="editor-selector"
          id="card-border-selector"
          value={this.props.cardBorder}
          onChange={this.handleBorderChange}
        >
          <option value="dashed">Dashed</option>
          <option value="dotted">Dotted</option>
          <option value="solid">Solid</option>
          <option value="double">Double</option>
          <option value="groove">Groove</option>
          <option value="ridge">Ridge</option>
          <option value="inset">Inset</option>
          <option value="outset">Outset</option>
          <option value="none">None</option>
        </select>
      </div>
    );
  }

  renderBorderColor() {
    if (this.props.cardBorder !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Color: </label>
          <ColorPicker
            className="middle"
            color={this.props.borderColor}
            alpha={100}
            defaultColor="#000000"
            onChange={this.handleBorderColorChange}
          />
        </div>
      );
    }
  }

  renderBorderSize() {
    if (this.props.cardBorder !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Size: </label>
          <input
            type="range"
            min="0"
            max="20"
            value={this.props.borderSize}
            className=" slidecontainer editor-slider"
            id="myRange"
            onChange={this.handleBorderSizeChange}
          />
        </div>
      );
    }
  }

  renderBorderRadius() {
    if (this.props.border !== "none") {
      return (
        <div className="editor-field">
          <label className="editor-label">Border Radius: </label>
          <input
            type="range"
            min="0"
            max="50"
            value={this.props.borderRadius}
            className="slidecontainer editor-slider"
            id="myRange"
            onChange={this.handleBorderRadiusChange}
          />
        </div>
      );
    }
  }

  renderBackgroundColor() {
    return (
      <div className="editor-field">
        <label className="editor-label">Background Color: </label>
        <ColorPicker
          className="middle"
          color={this.props.backgroundColor}
          alpha={100}
          defaultColor="#ffffff"
          onChange={this.handleBackgroundColorChange}
        />
      </div>
    );
  }

  renderSectionEditorDiv(render, section) {
    if (render) {
      return (
        <SectionEditor
          collectionItemType={this.props.collectionItemType}
          className="sectionEditor"
          imageFolders={this.props.imageFolders}
          onSectionUpdated={this.onSectionUpdated}
          section={section}
          backgroundImage={section.backgroundImage}
          editorState={this.props.editorState}
          onEditorStateChange={this.onEditorStateChange}
          deleteSection={this.deleteSection}
          deleteBackgroundImage={this.deleteSectionBackgroundImage}
          projectId={this.props.projectId}
          editTemplateRow={this.props.editTemplateRow}
        // templateVariables={this.props.templateVariables}
        />
      );
    }
  }

  renderDraggableSections() {
    return <DragDropContext onDragEnd={this.onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
          // style={getListStyle(snapshot.isDraggingOver)}
          >
            {Object.values(this.props.sections).sort((a, b) => a.index - b.index).map((section, index) => (
              <AccordionItem
                key={section.id}
                expanded={this.isSectionSelected(this.props.editSection, section)}
                uuid={section.id}
              >
                <Draggable key={section.id} draggableId={`section-${section.id}`} index={section.index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >

                      <AccordionItemTitle>
                        <div className="accordion_title-content">
                          <i className="fas fa-grip-lines drag-handle"></i>
                          {/* <i className="fas fa-grip-horizontal drag-handle"></i> */}
                          <h5 className="accordion_title-text">
                            Section - {section.name}
                          </h5>
                        </div>
                        <CopySectionButton sectionId={section.id} onCopySection={this.handleCopySection}/>
                        <div className="accordion__arrow" role="presentation" />
                      </AccordionItemTitle>
                    </div>
                  )}
                </Draggable>
                <AccordionItemBody>
                  {this.renderSectionEditorDiv(
                    this.isSectionSelected(this.props.editSection, section),
                    section
                  )}
                </AccordionItemBody>

              </AccordionItem>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  }

  renderCardSection() {
    return (
      <AccordionItem uuid="card">
        <AccordionItemTitle>
          <h5 className="accordion_card-title-text">{this.props.componentType}</h5>
          <div className="accordion__arrow" role="presentation" />
        </AccordionItemTitle>
        <AccordionItemBody>
          {this.renderName()}
          {this.renderCardStyle()}
          {this.renderTokenStyles()}
          {this.renderOrientation()}
          {this.renderQuantity()}
          {this.renderBorder()}
          {this.renderBorderRadius()}
          {this.renderBorderColor()}
          {this.renderBorderSize()}
          {this.renderBackgroundColor()}
          {this.renderBackgroundImage()}
        </AccordionItemBody>
      </AccordionItem>
    );
  }

  isSectionSelected(editSection, section) {
    if (editSection) {
      return this.props.editSection.id === section.id;
    }
    return false;
  }

  handleUploadStart() { }

  handleUploadError() { }

  handleUploadSuccess = filename => {
    storage
      .ref(`projects/${this.props.projectId}/images`)
      .child(filename)
      .getDownloadURL()
      .then(url =>
        this.props.onCardUpdated({
          backgroundImage: { name: filename, url: url }
        })
      );
  };

  handleProgress() { }

  handleFileUpload = event => {
    var reader = new FileReader();
    this.filename = event.target.files[0].name;

    reader.onloadend = () => {
      this.props.onCardUpdated({
        backgroundImage: {
          url: reader.result,
          uploaded: false,
          name: this.filename
        }
      });
    };

    var dataUrl = reader.readAsDataURL(event.target.files[0]);
  };

  deleteSectionBackgroundImage = (sectionId, uuid) => {
    this.onSectionUpdated(sectionId, { backgroundImage: null });
  };

  onAddNewSection = () => {
    this.props.onAddNewSection();
  };

  deleteBackgroundImage = () => {
    this.props.onCardUpdated({ backgroundImage: null });
  };

  renderOrientation() {
    return (
      <div className="editor-field">
        <label className="editor-label">Orientation: </label>
        <select
          className="editor-selector"
          id="card-size-selector"
          value={this.props.orientation}
          onChange={this.handleOrientationChange}
        >
          <option value="portrait">Portrait</option>
          <option value="landscape">Landscape</option>
        </select>
      </div>
    );
  }

  renderQuantity() {
    return (
      <div className="editor-field">
        <label className="editor-label">Quantity: </label>
        <select
          className="editor-selector"
          id="card-size-selector"
          value={this.props.quantity}
          onChange={this.handleQuantityChange}
        >
          {renderOptions(1, 40)}
        </select>
      </div>
    );
  }

  renderBackgroundImage() {
    return (
      <div className="editor-field">
        <label className="editor-label">Background Image: </label>
        <BackgroundImageSelector
          imageFolders={this.props.imageFolders}
          includeSvgs={false}
          backgroundImage={this.props.backgroundImage}
          onImageRemoved={this.deleteBackgroundImage}
          onImageSelected={this.onImageSelected}
        />
        {this.renderBackgroundSize(this.props.backgroundImage)}
      </div>)
  }

  renderBackgroundSize(backgroundImage) {
    if (backgroundImage) {
      return (
        <div className="editor-field">
          <label className="editor-label">Size: </label>
          <select
            className="editor-selector"
            id="card-size-selector"
            value={this.props.backgroundSize ? this.props.backgroundSize : 'cover'}
            onChange={this.handleBackgroundSizeChange}>
            <option value="cover">Fill</option>
            <option value="contain">Contain</option>
          </select>
        </div>
      )
    }
  }

  launchExportSettings = () => {
    this.setState({ enableExport: true })
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    this.props.changeSectionOrder(result.source.index, result.destination.index);
  }


  render() {
    return (
      <div className="editorPanelContainer">

        <Accordion onChange={this.handleAccordionChange}>
          {this.renderCardSection()}
          {this.renderDraggableSections()}
        </Accordion>

        {this.renderAddSectionButton()}
        {this.renderRemoveCustomBack()}
        {this.renderButtons()}
        {this.renderExportOptions()}
      </div>
    );
  }

  renderAddSectionButton() {
    if (!this.props.editTemplateRow) {
      return (
        <div data-testid="add-section-button" className="add-section-button" onClick={this.onAddNewSection}>
          <i className="fas fa-plus center-icon" /> Add Section
        </div>
      )
    }
  }

  renderRemoveCustomBack() {
    if (!this.props.editTemplateRow && this.props.removeCustomBack) {
      return (
        <div className="remove-back-button" onClick={this.handleRemoveCustomBack}>
          <i className="fas fa-minus center-icon" /> Remove Custom Back
        </div>
      )
    }
  }

  // renderExportButton = () => {
  //   if (this.state.enableExport) {
  //     return <Export {...this.props} />
  //   }
  // }
  handleExportComponent = (dpi, fileType, name) => {
      // event.preventDefault();
      // firebase.functions.useFunctionsEmulator('http://localhost:5001');
      var exportComponentFunction = firebase.functions.httpsCallable('exportComponentOnCall');
      exportComponentFunction({
        projectId: this.props.projectId, 
        collectionId: this.props.collectionId, 
        componentId: this.props.componentId, 
        width: this.props.width,
        height: this.props.height,
        dpi: parseFloat(dpi),
        fileType: fileType,
        authorization: firebase.auth.currentUser.xa 
      })
      .then(result => {
        download(`data:image/${fileType};base64,${result.data}`, `${name}.${fileType}`, `image/${fileType}`);
      }).catch(err => {console.log("error ocrred", err)});
    
  }

  renderExportOptions = () => {
    if(this.state.exportToggled){
      return (
        <ExportPanel
            projectId={this.projectId}
            collectionItemType={this.collectionId}
            componentId={this.componentId}
            rowNumber={null}
            handleExport={this.handleExportComponent}
            componentName={this.props.name}
            handleClose={this.handleToggleExport}
        />
      )
    }
  }

  renderSaveButton = () => {
    if (this.props.canEdit) {
      return (
        <button
          type="button"
          className="button save-button"
          onClick={this.props.save}
        >
          Save
        </button>
      )
    }
  }

  renderExportButton = () => {
    if (this.props.collectionItemType !== 'template') {
      return (
        <button
          type="button"
          className="button export-button"
          onClick={this.handleToggleExport}
        >
          Export
        </button>
      )
    }
  }

  renderButtons() {
    if (this.props.editTemplateRow) {
      return <button
        type="button"
        className="button save-button"
        onClick={this.props.back}>
        Back
      </button>
    } else {
      return <React.Fragment>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {this.renderSaveButton()}
          {this.renderExportButton()}
          {/* <button className="button" onClick={this.launchExportSettings}>Export</button>  */}
          

          {/* <ExportButton id="component" label="Export" /> */}
          {/* <button className="button" onClick={() => {generateExport(this.props)}}>Export</button>  */}
          {/* <div style={{}}> */}
          {/* <div id="export-component"></div> */}
          {/* </div>*/}
          <button
            type="button"
            className="button cancel-button"
            onClick={this.props.cancel}
          >
            Cancel
          </button>
        </div>
      </React.Fragment>
    }
  }
}

export function generateExport(props) {
  const backgroundPromise = new Promise((resolve, rejected) => {
    if (props.backgroundImage) {
      toDataUrl(props.backgroundImage.url, (result) => {
        resolve(result);
      })
    } else {
      resolve(null)
    }
  })

  const sectionPromises = Object.keys(props.sections).map(id => {
    return new Promise((resolve, rejected) => {
      if (props.sections[id].backgroundImage) {
        toDataUrl(props.sections[id].backgroundImage.url, (result) => {
          resolve(Object.assign({ id: id, backgroundImage: result }));
        })
      }
    })
  })

  Promise.all([backgroundPromise].concat(sectionPromises)).then(
    ([backgroundImageResult, sectionPromiseResult]) => {
      if (backgroundImageResult) {
        console.log("background image promise result", backgroundImageResult);
      }

      if (sectionPromiseResult) {
        sectionPromiseResult.map(result => console.log("section result", result));
      }


    }
  ).catch(e => console.log("exception happened while getting background images", e));

}


const ExportButton = ({ id, label }) => (<div className="tc mb4 mt2">
  <div id="myMm" style={{ height: "1mm" }} />

  <button
    onClick={() => {
      const input = document.getElementById(id);
      const config = { allowTaint: true };
      config.allowTaint = true;

      html2canvas(input, { allowTaint: true, useCORS: true, scale: 5, backgroundColor: '#000000' })
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          download(imgData, `test.png`, 'image/png');
        });

    }}
  >
    {label}
  </button>
</div>);
