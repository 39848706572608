import React, { Component } from "react";
import Select from 'react-select';

export default class BackgroundImageSelector extends Component {

    constructor(props) {
        super(props);
    }

    handleImageSelected = (selected) => {
        if(selected){
            this.props.onImageSelected(selected.image);
        }else{
            this.props.onImageRemoved();
        }
    }

    calculateImageSelected(backgroundImage) {
        if(backgroundImage){
            return {label: backgroundImage.name, value: backgroundImage.id}
        }else{
            return {label: '', value: ''}
        }
    }

    renderFolderImages = () => {
        const imageSelected = this.calculateImageSelected(this.props.backgroundImage);
        const imageFolders = this.props.imageFolders;
        const options = Object.keys(imageFolders)
            .map(id => Object.assign(
                {
                    label: imageFolders[id].name,
                    options: Object.keys(imageFolders[id].files)
                        .map(imageId => Object.assign(
                            {
                                enabled: !imageFolders[id].files[imageId].type.includes('svg')
                                    || (this.props.includeSvgs && imageFolders[id].files[imageId].type.includes('svg')),
                                label: imageFolders[id].files[imageId].name,
                                value: imageId,
                                image: imageFolders[id].files[imageId]
                            }))
                }))

        const customStyles = {
            control: (base, state) => ({
                ...base,
                height: '25px',
                minHeight: '25px',
            }),
            menu: (base, state) => ({
                ...base,
                zIndex: '3'
            }),
            menuList: (base, state) => ({
                ...base,
                zIndex: '3'
            }),
            group: (base, state) => ({
                ...base,
                zIndex: '3'
            }),
            valueContainer: (base, state) => ({
                ...base,
                height: '25px',
                minHeight: '25px',
            }),
            indicatorsContainer: (base, state) => ({
                ...base,
                height: '25px',
                minHeight: '25px',
            }),
        };
        return <Select
            className="image-selector"
            styles={customStyles}
            isOptionDisabled={(option) => !option.enabled}
            value={imageSelected}
            onChange={this.handleImageSelected}
            options={options}
            isClearable
            isSearchable
        />
    }

    render() {
        if (this.props.imageFolders) {
            return (
                this.renderFolderImages()
            )
        } else {
            return null;
        }
    }
}