import React, { Component, useState, useEffect } from 'react';
import withAuthorization from "../../components/withAuthorization";
import { firebase } from "../../firebase";
// import Timestamp from "firebase/firebase-firestore";
import './Project.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import '../../css/react-accordion.css';
import * as routes from "../../constants/routes";
import ComponentCollection from "./collection/ComponentCollection";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ComponentSetCreationWizard from './create/ComponentSetCreationWizard';
import { CardTypes } from '../components/CardTypes';
import { TokenTypes } from '../components/TokenTypes';
import ReactGA from 'react-ga';
import StorageFolders from '../../Storage/StorageFolders';
import Permissions, { hasPermissionToEdit } from './permissions';

function Project({ projectId, authUser, history, set, focus, id }) {

    const firestore = firebase.firestore;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [project, setProject] = useState({
        tags: [],
        name: '',
        minPlayers: 1,
        maxPlayers: 10,
        images: [],
        owner: authUser.uid,
        ownerEmail: authUser.email,
        ownerName: authUser.displayName,
        permissions: []
    });
    const [addNewDeck, setAddNewDeck] = useState(false);
    const [addCollection, setAddCollection] = useState(false);
    const [addNewTokens, setAddNewTokens] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [decks, setDecks] = useState(new Map());
    const [tokens, setTokens] = useState(new Map());
    const [permissions, setPermissions] = useState([]);
    const [imageFolders, setImageFolders] = useState(new Map());
    const [updatingCollectionName, setUpdatingCollectionName] = useState(false);

    useEffect(() => {

        if (projectId !== 'new') {
            const projectPromise = firestore.collection('projects').doc(
                projectId).get().catch(err => {
                    console.log('Error getting documents', err);
                });
            const collectionPromise = firestore.collection("projects").doc(
                projectId).collection("collections")
                .where("type", "==", "cards")
                .get()
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });

            const tokenSetPromise = firestore.collection("projects").doc(
                projectId).collection("collections")
                .where("type", "==", "tokens")
                .get()
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });

            Promise.all([projectPromise, collectionPromise, tokenSetPromise]).then(
                ([snapshot, querySnapshot, tokenSetSnapshot]) => {
                    const project = snapshot.exists ? snapshot.data()
                        : project;

                    if (querySnapshot) {
                        var deckCollection = new Map();
                        querySnapshot.forEach(doc => {
                          deckCollection[doc.id] = Object.assign({}, doc.id, doc.data());
                        });

                        setDecks(deckCollection);
                    }

                    if (tokenSetSnapshot) {
                        var tokenCollection = new Map();
                        tokenSetSnapshot.forEach(tokenDoc => {
                          tokenCollection[tokenDoc.id] = Object.assign({}, tokenDoc.id, tokenDoc.data());
                        });

                        setTokens(tokenCollection);
                    }

                    const updatedPermissions = project.permissions ? Object.keys(project.permissions).map(email => Object.assign({}, { email: email }, { level: project.permissions[email] }, { id: "" })) : [];
                    setProject(project);
                    setPermissions(updatedPermissions);

                    if (project.description) {
                        setEditorState(EditorState.createWithContent(convertFromRaw(project.description)));
                    }
                }).catch();


            firestore.collection('projects')
                .doc(projectId)
                .collection('folders')
                .get()
                .then(snapshots => {
                    snapshots.forEach(folder => {

                        firestore
                            .collection('projects')
                            .doc(projectId)
                            .collection('folders')
                            .doc(folder.id)
                            .collection('assets')
                            .get()
                            .then(snapshots => {

                                var images = {};
                                snapshots.forEach(image => {
                                    images = Object.assign({}, images, { [image.id]: Object.assign({}, { ...image.data() }, { id: image.id }) })
                                })

                                setImageFolders(Object.assign({}, imageFolders, {
                                        [folder.id]: Object.assign({}, folder.data(), { images })
                                    }))
                            })
                            .catch(error => console.log(`error getting all assets for folder ${id}`, error))
                    })
                })
                .catch(error => console.log("Error occurred while trying to retrieve storage folders"));
        }
    }, [projectId]);

    const handleDeleteCollection = (collectionId, collectionType) => {
        var deleteCollectionFunction = firebase.functions.httpsCallable('deleteCollection');
        ReactGA.event({ category: 'PROJECT', action: 'DELETE_COLLECTION' });
        deleteCollectionFunction({ projectId: projectId, collectionId: collectionId }).then(function (result) {
        });
    
        if (collectionType === 'cards') {
          var updatedCollections = removeKey(decks, collectionId);
          setDecks(updatedCollections);
        } else if (collectionType === 'tokens') {
          var updatedCollections = removeKey(tokens, collectionId);
          setTokens(updatedCollections);
        }
    
      }

    const handleCollectionNameChange = (id, props) => {
        if (decks[id]) {
          setDecks(Object.assign({}, decks, {
              [id]: Object.assign({}, decks[id], props)}));
        } else if (tokens[id]) {
          setTokens(Object.assign({}, tokens, {
              [id]: Object.assign({}, tokens[id], props)}));
        }
    
        setUpdatingCollectionName(true);
      };

    const getTagOptions = () => {
        return [
          { id: 'Cooperative', text: 'Cooperative' },
          { id: 'Strategy', text: 'Strategy' },
          { id: 'War', text: 'War' },
          { id: 'Thematic', text: 'Thematic' },
          { id: 'Horror', text: 'Horror' },
          { id: 'Family', text: 'Family' }
        ]
      }

    const handleNameChange = (event) => {
      setProject(Object.assign({}, {
          ...project,
          name: event.target.value
        }));
        setIsChanged(true);
    };

    const handleUploadSuccess = (filename) => {
      firebase.storage.ref(`projects/${projectId}/images`)
        .child(filename)
        .getDownloadURL()
        .then(
          url => setProject({
              ...project,
              images: project.images.concat(url)
          }));
    };

    const handleDescriptionChange = (event) => {
      setProject(Object.assign({
          ...project,
          description: event.target.value
      }));
    };

    const handleMinPlayersChange = (event) => {
      setProject(Object.assign({
          ...project,
          minPlayers: event.target.value
        }));
    };
  
    const handleMaxPlayersChange = (event) => {
      setProject(Object.assign({
          ...project,
          maxPlayers: event.target.value
        }));
    };
  
    const handleDelete = (index) => {
      const tags = project.tags;
      setProject(Object.assign({
          ...project,
          tags: tags.filter((tag, index) => index !== index)
      }));
    }
  
    const handleAddition = (tag) => {
      const newTags = project.tags == null ? new Array().concat(tag) : project.tags.concat(tag);
      setProject(Object.assign({ ...project, tags: newTags }));
    }

    const handleDrag = (tag, currPos, newPos) => {
      const tags = [...project.tags];
      const newTags = tags.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      setProject(Object.assign({
          ...project,
          tags: newTags
      }));
    }

    const createProject = () => {
      const firestore = firebase.firestore;
      ReactGA.event({ category: 'PROJECT', action: 'CREATE' });
      firestore.collection('projects')
        .add(project)
        .then(projectRef => {
          firestore
            .collection("projects")
            .doc(projectRef.id)
            .collection("folders")
            .add({ name: 'Images' })
            .then(ref =>
              history.push(routes.PROJECTS + '/' + projectRef.id)
            )
            .catch(error => console.log("error occurred while trying to create images folder for new project", error))
        });
    };

    const handleCreateCollection = (name, componentType, style, data) => {
      var projectPromise = firestore.collection(`projects/${projectId}/collections`)
        .add({ name, type: componentType, style, data })
        .then(ref => {
          ReactGA.event({ category: 'PROJECT', action: 'CREATE_COLLECTION' });
          if (componentType === 'cards') {
            setDecks(Object.assign({}, decks, {
                [ref.id]: Object.assign({ name, type: componentType, style, data })
              }));
            setAddNewDeck(false);
          } else if (componentType === 'tokens') {
            setTokens(Object.assign({}, tokens, {
                [ref.id]: Object.assign({ name, type: componentType, style, data })
              }));
            setAddNewTokens(false);
          }
        });
    }

    const handleCancelDeckCreation = () => {
      setAddNewTokens(false);
    }
  
    const handleCancelTokenSetCreation = () => {
      setAddNewTokens(false);
    };
  
    const onEditorStateChange = (editorState) => {
      setEditorState(editorState);
      setProject(Object.assign({
          ...project,
          description: convertToRaw(editorState.getCurrentContent())}));
    };

    const cancel = () => {
      history.push(routes.PROJECTS);
    };

    const renderUploadedImages = () => {
      if (project.images) {
        var x = 0;
        return project.images.map(
          image =>
            <div key={x++}>
              <img style={{
                maxHeight: '200px',
                maxWidth: '200px',
                marginRight: '1em',
                objectFit: 'contain'
              }}
                src={image} />
            </div>)
      }
    };

    const updatePermissionRow = (index, column, value) => {
      var existingPermissions = permissions.map( (item, i) => {
        if(i === index){
          return Object.assign({}, item, {[column] : value});
        }else {
          return item;
        }
      })
  
      const updatedPermissions = Object.assign({});
      existingPermissions.forEach(permission => updatedPermissions[permission.email] = permission.level);
     
      firebase.firestore.collection('projects')
      .doc(projectId)
      .set({ 
        ...project,
        permissions: updatedPermissions,  
        sharedUsers: Object.keys(updatedPermissions)
      });
      setPermissions(existingPermissions);
    }

    const deletePermissionRow = (index) => {
      const reducedPermissionList = permissions.filter( (item, i) => 
        i !== index
      );
  
      const updatedPermissions = Object.assign({});
      reducedPermissionList.forEach(permission => updatedPermissions[permission.email] = permission.level);

      firebase.firestore.collection('projects')
      .doc(projectId)
      .set({
        ...project, 
        permissions: updatedPermissions, 
        sharedUsers: Object.keys(updatedPermissions)
      });
      setPermissions(reducedPermissionList);
    }
  
    const addPermissionRow = () => {  
      const permissionId = firebase.firestore.collection("permissions").id;
      const updatedPermissions = permissions.concat(Object.assign({}, { email: "", level: "write", id: "", projectId: projectId }));
      setPermissions(updatedPermissions);
    }

    const handleFileRemoved = (folderId, fileId) => {
      const folder = imageFolders[folderId];
      const images = removeKey(folder.images, fileId)
  
      setImageFolders(Object.assign({}, imageFolders, {
          [folderId]: Object.assign({}, ...folder, { images })
        }));
    }

    const removeKey = (obj, deleteKey) =>{
      let clone = Object.assign({}, obj);
      delete clone[deleteKey];
      return clone;
    }
  
    const handleImageAdded = (folderId, image) => {
      const folder = imageFolders[folderId];
      const images = Object.assign({}, folder.images, { [image.id]: Object.assign({}, { ...image }) });
  
      setImageFolders(Object.assign({}, imageFolders, {
          [folderId]: Object.assign({}, ...folder, { images })
        }));
    }
  
    const renderPermissions = () => {
      console.log("permission in render", permissions);
      if (projectId && projectId !== 'new' 
        && project.owner === authUser.uid) {
        return <Permissions
          projectId={projectId}
          permissions={permissions}
          updatePermissionRow={updatePermissionRow}
          addPermissionRow={addPermissionRow}
          deletePermissionRow={deletePermissionRow}/>
      }
    }
  
    const renderProjectAssets = () =>{
      if (projectId && projectId !== 'new') {
        return <div>
          <h2 className="collection-header">Assets</h2>
          <StorageFolders
            canEdit={hasPermissionToEdit(project, authUser)}
            projectId={projectId}
            handleFileRemoved={handleFileRemoved}
            handleImageAdded={handleImageAdded} />
        </div>
      }
    }
  
    const createDeck = () => {
      var newDeck = {
        name: 'Deck Name'
      };
  
      firestore.collection('projects/' + projectId + '/decks')
        .add({ name: 'Deck Name' })
        .then(ref => {
          setDecks(Object.assign({}, decks, {
              [ref.id]: Object.assign({}, newDeck)
            }));
        });

      setAddCollection(false);
    };
  
    const renderCollection = (collection) => {
      const focusRef = updatingCollectionName ? null : React.createRef();
      if (collection) {
        return <div>
          <Accordion accordion={false}>
            {Object.keys(collection).map(id => (
              <AccordionItem key={id} uuid={id} expanded={set === id ? true : false}>
                <AccordionItemTitle className="my_accordion__title">
                  <h3 className="accordion-collection-header">{collection[id].name}</h3>
                  <div className="accordion__arrow" role="presentation"></div>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <ComponentCollection key={id}
                    imageFolders={imageFolders}
                    focusRef={focusRef}
                    focusId={focus}
                    collectionId={id}
                    projectId={projectId}
                    collection={collection[id]}
                    collectionType={collection[id].type}
                    collectionData={collection[id].data}
                    handleDeleteCollection={handleDeleteCollection}
                    handleCollectionNameChange={handleCollectionNameChange} />
                </AccordionItemBody>
              </AccordionItem>
            ))
            }
          </Accordion>
        </div>
      }
    };

    const renderCreateOrSaveButton = () => {
      if (projectId === 'new') {
        return <div><button type="button" className="button save-button"
          onClick={createProject}>Create Game</button>
          <button type="button" className="button cancel-button"
            onClick={cancel}>Cancel
          </button>
        </div>
      }
    };


    const addNewDeckFunction = () => {
      setAddNewDeck(true);
    }

    const addNewTokensFunction = () => {
      setAddNewTokens(true);
    }

    const renderProjectComponents = () => {
      if (projectId && projectId !== 'new') {
        return <div>
          <h2 className="collection-header">Decks</h2>
          {renderCollection(decks)}
          <ComponentSetCreationWizard
            types={CardTypes}
            collectionType='Deck'
            componentType='cards'
            styleName='Card Styles'
            handleCreateCollection={handleCreateCollection}
            handleCancel={handleCancelDeckCreation}
            open={addNewDeck} />
          {!addNewDeck ?
            <div className="project_field">
              <div className="add-collection-button"
                onClick={addNewDeckFunction}>
                <i className="fas fa-plus add-collection-icon"></i>
              </div>
            </div>
            : <div />}
          <h2 className="collection-header">Tokens</h2>
          {renderCollection(tokens)}
          <ComponentSetCreationWizard
            types={TokenTypes}
            collectionType='Tokens'
            componentType='tokens'
            styleName='Token Styles'
            handleCreateCollection={handleCreateCollection}
            handleCancel={handleCancelTokenSetCreation}
            open={addNewTokens} />
          {!addNewTokens ?
            <div className="project_field">
              <div className="add-collection-button"
                onClick={addNewTokensFunction}>
                <i className="fas fa-plus add-collection-icon"></i>
              </div>
            </div>
            : <div />}
          {/* <h2 className="collection-header">Models</h2> */}
        </div>
      }
    }

    const saveName = () => {
        if (projectId !== 'new') {
          var projectsRef = firebase.firestore.collection('projects')
            .doc(projectId)
            .set({ name: project.name }, { merge: true });
          setIsChanged(false);
        }
      };

      const save = () => {
        ReactGA.event({ category: 'PROJECT', action: 'SAVE' });
        const firestore = firebase.firestore;
        var projectsRef = firestore.collection('projects')
          .doc(projectId)
          .set(project);
    
        var deckCollection = firestore.collection('projects')
          .doc(projectId)
          .collection("collections");
    
        // Object.keys(collections).forEach(id =>
        //   saveDeck(deckCollection, id, decks[id]));
        history.push(routes.PROJECTS);
      };

      return (<div className="project_editor">
      <div className="project_field">
        <label>Game Name </label>
        <input className="project_input input_field" value={project.name}
          onChange={handleNameChange}
          onBlur={saveName} />
        {/* <i className="fas fa-cog settings-icon" /> */}
      </div>
      {/* <div>
        <div className="project_field text_area">
          <label>Game Description </label>
        </div>
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this.onEditorStateChange}
          editorClassName="editor-wrapper"
        />
      </div> */}
      {/* <div className="project_field">
        <label>Player Count </label>
        <select className="editor-selector" id="min-player-selector"
          value={this.state.project.minPlayers}
          onChange={this.handleMinPlayersChange}>
          {renderOptions(0, 10)}
        </select>

        <label> to </label>
        <select className="editor-selector" id="min-player-selector"
          value={this.state.project.maxPlayers}
          onChange={this.handleMaxPlayersChange}>
          {renderOptions(1, 20)}
        </select>
      </div> */}
      {/* <div className="project_field">
        <label>Genre </label>
        <ReactTags
          inline
          tags={this.state.project.tags == null ? [] : this.state.project.tags}
          suggestions={this.getTagOptions()}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          delimiters={delimiters} />
      </div> */}
      {/* {this.renderImageGallery()} */}

      <div className="project_field" style={{ width: '100%' }}>
        {renderCreateOrSaveButton()}
      </div>

      <p />
      {renderProjectComponents()}
      {renderProjectAssets()}
      {renderPermissions()}
    </div>);
}


const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Project);