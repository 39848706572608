export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const ABOUT = '/about';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const MARKET = '/market';
export const MY_GAMES = '/games';
export const DEMO = '/editor/component/demo';
export const CARD = '/editor/component';
export const CARD_TEMPLATE = '/projects/:projectId/collections/:collectionId/components/:componentId';
export const CARD_EXPORT = '/projects/:projectId/collections/:collectionId/components/:componentId/export';
export const PROJECTS = '/projects';
export const PROJECT_VIEW = '/projects/:projectId';
export const NEW_PROJECT = '/projects/new';
export const DECK_PRINT_VIEW = '/projects/:projectId/collections/:collectionId/print';
export const PROJECT_COLLECTION_VIEW = '/projects/:projectId/collections/:collectionId';