import React, { Component } from "react";
import "react-table/react-table.css";

export default class ActionsCell extends Component {
    constructor(props) {
        super(props);
    }

    handlePreview = () => {
        if (this.props.handlePreview) {
            this.props.handlePreview(this.props.cellInfo)
        }
    }

    handleEditRow = () => {
        if(this.props.handleEditRow) {
            this.props.handleEditRow(this.props.cellInfo.original.id)
        }
    }

    handleDelete = () => {
        if (this.props.handleDelete) {
            this.props.handleDelete(this.props.cellInfo.original.id)
        }
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'columns' }}>
                {/* <Link className="edit-link" key={this.props.id} to={this.props.editSelectedPath}> */}
                <div className="icon-cell">
                    <i className="fas fa-edit edit-icon" onClick={this.handleEditRow}/>
                </div>
                {/* </Link> */}
                <div className="icon-cell">
                    <i className="far fa-image preview-icon" onClick={this.handlePreview} />
                </div>
                <div className="icon-cell">
                    <i className="fas fa-trash-alt delete-row-icon" onClick={this.handleDelete} />
                </div>
            </div>)
    }
}