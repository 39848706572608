import React, { Component } from 'react';
import withAuthorization from "../../components/withAuthorization";
import { firebase } from "../../firebase";
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';
import ProjectThumbNail from './ProjectThumbNail';
import ReactGA from 'react-ga';

class Projects extends Component {

  constructor(props) {
    super(props);
    ReactGA.pageview('/projects');
    this.state = {};
  }

  handleCreateProject = () => {

  }

  handleProjectSelected = (id) => {
    this.setState({ projectSelected: id });
  }

  render() {
    return <React.Fragment>
      <div style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '3em',
        marginBottom: '2.5em',
        display: 'flex'
      }}>
        <div className="add-project-button">
          <Link to={routes.NEW_PROJECT}>
            <div className="add-project-outter-square">
              <div className="center-text">Create</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="project-list">
        {this.renderProjectDivs()}
        {this.renderSharedProjectDivs()}
      </div>
    </React.Fragment>
  }

  renderProjectDivs = () => {
    if (this.state.projects) {
      return Object.keys(this.state.projects).map(id =>
        <div key={id} className="project-thumbnail" style={{ position: 'relative' }}>
          <ProjectThumbNail id={id}
            key={id}
            project={this.state.projects[id]}
            deleteProject={this.deleteProject} 
            onClick={this.handleEditProject}>
              <div className="project-name">
                {this.state.projects[id].name}
              </div>
          </ProjectThumbNail>
        </div> 
      )
    }
  }

  renderSharedProjectDivs = () => {
    if (this.state.sharedProjects) {
      return Object.keys(this.state.sharedProjects).map(id =>
        <div key={id} className="project-thumbnail" style={{ position: 'relative' }}>
          <ProjectThumbNail id={id}
            key={id}
            project={this.state.sharedProjects[id]}
            onClick={this.handleEditProject}>
              <i className="fas fa-user-friends permissions-icon" />
              <div className="project-name">{this.state.sharedProjects[id].name}</div>
          </ProjectThumbNail>
        </div>
      )
    }
  }

  deleteProject = (id) => {
    ReactGA.event({ category: 'PROJECT', action: 'DELETE' });
    var deleteProjectFunction = firebase.functions.httpsCallable('deleteProject');
    deleteProjectFunction({ projectId: id }).then(function (result) {
      // Read result of the Cloud Function.
      var sanitizedMessage = result;
    });

    // firebase.firestore.collection('projects').doc(id).delete().then(
    // ).catch(
    //   err => console.log("issue with deleting project", err, id)
    // );

    var newProjectsList = this.removeKey(this.state.projects, id);
    this.setState({ projects: newProjectsList });
  }

  removeKey(obj, deleteKey) {
    let clone = Object.assign({}, obj);
    delete clone[deleteKey];
    return clone;
  }

  componentDidMount() {
    //find all projects owned by this user
    var projectsRef = firebase.firestore.collection('projects');
    // authUser.uid
    var query = projectsRef.where('owner', '==', this.props.authUser.uid).get()
      .then(snapshot => {
        var result = snapshot.docs.reduce(function (map, obj) {
          map[obj.id] = obj.data();
          return map;
        }, {});
        this.setState({ projects: result });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });

      firebase.firestore
      .collection('projects')
      .where('sharedUsers', 'array-contains', this.props.authUser.email).get()
      .then(snapshot => {
        var result = snapshot.docs.reduce(function (map, obj) {
          map[obj.id] = obj.data();
          return map;
        }, {});
        this.setState({ sharedProjects: result });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
   }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Projects);
