import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';

var config = {
	apiKey: "AIzaSyD23iNelfeJ4HVbks30uZ66OTdN83wxl84",
	authDomain: "board-game-haven.firebaseapp.com",
	databaseURL: "https://board-game-haven.firebaseio.com",
	projectId: "board-game-haven",
	storageBucket: "board-game-haven.appspot.com",
	messagingSenderId: "599585888243"
};
if (!firebase.apps.length) {
	var app = firebase.initializeApp(config);
}

const db = firebase.database();
const firestore = firebase.firestore();
const settings = {/* your settings... */};
firestore.settings(settings);

const functions = firebase.functions();
const auth = firebase.auth();
const storage = firebase.storage();
const provider = new firebase.auth.GoogleAuthProvider();

export {
  auth,
	db,
	functions,
	firestore,
	provider,
	storage,
};

export default auth;
