import React, { Component } from 'react';
import './permissions.css';
import ReactTable from "react-table";
import "react-table/react-table.css";
import EditableTextField from '../table/EditableTextField';
import DeleteCell from '../table/DeleteCell';
import DropDownSelectorCell from '../table/DropDownSelectorCell';

export function hasWritePermission(permissions, authUser){
    return permissions && permissions[authUser.email] === 'write';
}

export function hasPermissionToEdit(project, authUser){
    return project.owner === authUser.uid || hasWritePermission(project.permissions, authUser)
}


export default class Permissions extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    handleCellUpdated = (id, index, column, value) => {
        this.props.updatePermissionRow(index, column, value);
    }

    addPermissionRow = () => {
        this.props.addPermissionRow();
    }

    handleDelete = (id, index) => {
        this.props.deletePermissionRow(index);
    }

    renderEditableTextField = (cellInfo) => {
        return (
            <EditableTextField cellInfo={cellInfo} handleCellUpdated={this.handleCellUpdated} />
        );
    }

    renderDeleteCell = (cellInfo) => {
        return (
            <DeleteCell cellInfo={cellInfo}
                handleOnClick={this.handleDelete} />
        )
    }

    renderDropDown = (cellInfo) => {
        return (
            <DropDownSelectorCell
                cellInfo={cellInfo}
                handleOnChange={this.handleCellUpdated}>
                <option value="write" key="write">Write</option>
                <option value="read" key="read">Read Only</option>
            </DropDownSelectorCell>
        )
    }

    render() {
        var columns = [];
        columns.push({
            Header: 'email',
            accessor: 'email',
            Cell: this.renderEditableTextField
        });

        columns.push({
            Header: 'level',
            accessor: 'level',
            maxWidth: 120,
            Cell: this.renderDropDown
        });

        columns.push({
            Header: 'delete',
            accessor: 'delete',
            maxWidth: 100,
            Cell: this.renderDeleteCell
        });

        const minTableSize = this.props.permissions ? this.props.permissions.length : 0;
        console.log("wihtin permissions component", this.props.permissions);
        return (
            <div className="permissions">
                <h2 className="collection-header">
                    <i className="fas fa-user-friends permissions-icon" />
                    Permissions</h2>
                <ReactTable
                    NoDataComponent={() => null}
                    data={this.props.permissions}
                    columns={columns}
                    minRows={minTableSize}
                    showPagination={false}
                    sortable={false}
                    className="-striped -highlight" />

                <div className="project_field">
                    <div className="add-template-component-button"
                        onClick={this.addPermissionRow}>
                        <i className="fas fa-plus add-template-component-icon"></i>
                    </div>
                </div>
            </div>
        )
    }
}