import React, { Component } from 'react';
import '../GameComponent.css';
import { hexToRGB } from '../../../constants/RGBConverter'
import { styles } from './SectionHelper';
import QRCode from 'qrcode.react';
import Moveable from 'react-moveable';

export default class Section extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleSectionSelect = (event) => {
    if (this.props.canEdit) {
      this.props.onSectionSelect(this.props.section.id);
    }
  };

  handleSectionDrag = (e, d) => {
    this.props.onSectionUpdated(this.props.section.id,
      {
        x: d.x,
        y: d.y
      });

      e.stopPropagation();
  };

  handleSectionResize = (e, direction, ref, delta, position) => {
    this.props.onSectionUpdated(this.props.section.id,
      {
        width: `${ref.offsetWidth}`,
        height: `${ref.offsetHeight}`,
        x: position.x,
        y: position.y
      });
  };

  calculateImageUrl() {
    if (this.props.section.backgroundImage) {
      return 'url(' + this.props.section.backgroundImage.url + ')';
    }
    return 'none';
  }

  htmlContent = () => {
    if (this.props.section.backgroundImage && this.props.section.backgroundImage.data) {
      return `<div style={{}}>${this.props.section.backgroundImage.data}</div>`
    } else {
      return this.props.section.htmlContent
    }
  }

  onDrag = (transform) => {
    this.props.onSectionUpdated(this.props.section.id, 
      { 
        x: transform.left, 
        y: transform.top
      }
    )
  }

  onDragEnd = (transform) => {
    this.props.onSectionSelect(this.props.section.id);
  }


  onResize = (transform) => {
    this.props.onSectionUpdated(this.props.section.id, 
      { 
        x: transform.left, 
        y: transform.top,
        width: `${transform.width}`,
        height: `${transform.height}`
      }
    )
  }

  onRotate = (transform) => {
    this.props.onSectionUpdated(this.props.section.id, 
      { 
        rotate: transform.rotate
      }
    )
  }

  renderSection() {
    var className = (this.props.isSelected ? "section selected" : "section not-selected");
    if (this.props.canEdit) {
      className = className.concat(` editable-section section-${this.props.section.id}`);
    }

    var backgroundImageUrl = this.calculateImageUrl();
    const htmlContent = this.htmlContent();
    return (
      <React.Fragment>
        {this.props.isSelected?<Moveable
        target={document.querySelector(`.section-${this.props.section.id}`)}
        container={document.querySelector(".component")}
        draggable={this.props.isSelected}
        resizable={this.props.isSelected}
        onResize={this.onResize}
        onDrag={this.onDrag}
        onDragEnd={this.onDragEnd}
        // scalable={true}
        origin={false}
        rotatable={this.props.isSelected}
        onRotate={this.onRotate}
        throttleRotate={15}
      />:null}
        <div id={this.props.section.id}
          className={className}
          style={{
            color: this.props.section.svgColor ? `${hexToRGB(this.props.section.svgColor.replace('#', ''),
              this.props.section.svgColorAlpha)}` : 'none',
            backgroundColor: this.props.section.backgroundColor ? hexToRGB(this.props.section.backgroundColor.replace('#', ''),
              this.props.section.backgroundColorAlpha) : "",
            borderColor: `${hexToRGB(this.props.section.borderColor.replace('#', ''),
              this.props.section.borderColorAlpha)}`,
            borderColorAlpha: `${this.props.section.borderColorAlpha}`,
            borderWidth: `${this.props.section.borderSize}px`,
            borderStyle: `${this.props.section.border}`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: backgroundImageUrl,
            borderRadius: `${this.props.section.borderRadius}${this.props.section.shape === 'oval' ? '%' : 'px'}`,
            zIndex: `${this.props.isSelected ? 200 : this.props.section.zIndex}`,
            width: `${this.props.section.width}px`,
            height: `${this.props.section.height}px`,
            top: `${this.props.section.y}px`,
            left: `${this.props.section.x}px`,
            transform: `rotate(${this.props.section.rotate}deg)`
          }}
          onClick={this.handleSectionSelect}>
          <div dangerouslySetInnerHTML={{ __html: this.htmlContent() }} />
          {this.renderQRCode()}
        </div>
        </React.Fragment>
      )
  }

  renderQRCode() {
    if (this.props.section.qrcode && this.props.section.qrcode.value) {
      return <QRCode
        style={{ width: '100%', height: '100%' }}
        fgColor={this.props.section.qrcode.color}
        value="http://facebook.github.io/react/" renderAs="svg" />
    }
  }

  render() {
    return this.renderSection();
  }
}
