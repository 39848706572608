import React, { Component } from "react";
import "react-table/react-table.css";

export default class EditableTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
        value: this.props.cellInfo.value?this.props.cellInfo.value:'',
        componentId: this.props.cellInfo.original.id
    }
  }

  handleTextFinished = (event) =>{
      if(this.props.handleCellUpdated && this.state.isChanged){
        this.setState({isChanged: false});
        this.props.handleCellUpdated(this.state.componentId, this.props.cellInfo.index, this.props.cellInfo.column.id, this.state.value);
      }
  }

  handleTextChange = (event) =>{
      this.setState({value: event.target.value, isChanged: true});
  }

  render() {
    return (<input
        value={this.state.value}
        className="table_input-field"
        onChange={this.handleTextChange}
        onBlur={this.handleTextFinished}
      />)
  }
}