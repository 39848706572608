import React, { Component } from 'react';
import ReactGA from 'react-ga';
import './Home.css';

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    ReactGA.pageview('/');
    this.state = {
    };
  }

  render() {
    return (
      <div style={{
        textAlign: 'center',
        lineHeight: '2em',
        fontSize: '1.5em',
        fontFamily: 'serif',
        padding: '0 1em'
      }}>
        {/* <h2 style={{margin: 'auto', paddingTop: '2em'}}>Bring your ideas to life</h2> */}
        <div className="large-font"> Bring your ideas to life </div>
        <img style={{ height: '10em', margin: '1em' }} src='/BoardGameHavenLogo6.png' />
        <div className="text-body">
          Board Game Haven is a site to help individuals create board game prototypes with ease.  Currently, the website supports
          creating card decks and token sets.  Create your card or token by either importing an image or start from scratch
          and add sections, text, images, etc.  When you want to test out your prototype, print out the deck/set and
          every item will print to size.
          We are continually adding improvements and features.
          Any feedback is welcome at contact@boardgamehaven.com
        </div>

        <div className="home-presentation">

        <div className="two-tile">
          <div className="home-presentation-tile">
            <div className="large-font">Design Decks</div>
            <img src='/images/home/Deck Creation.jpg' />
          </div>
          <div className="home-presentation-tile">
            <div className="large-font">Design Tokens</div>
            <img src='/images/home/Token Creation.jpg' />
          </div>
        </div>
        <div className="two-tile">
          <div className="home-presentation-tile">
            <div className="large-font">Intuitive Editor</div>
            <img src='/images/home/Card Editor Small.jpg' />
          </div>
          <div className="home-presentation-tile">
            <div className="large-font">Templating Made Easy</div>
            <img src='/images/home/Template Preview.jpg' />
          </div>
        </div>
        </div>

      </div>
    );
  }
}