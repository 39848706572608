import React, {Component} from 'react';
import './Project.css';
import {Link} from 'react-router-dom';

export default class OverlayChecked extends Component {
  constructor(props){
    super(props);
  }

  handleOnClickEvent= () =>{
    if(this.props.handleOnClickEvent){
      this.props.handleOnClickEvent(this.props.id);
    }
  }

  render(){
    return <div>
            <div onClick={this.handleOnClickEvent}>
              {this.displayChecked()}
              {this.props.children}
            </div>
          </div>
  }

  displayChecked(){
    if(this.props.isSelected){
      return <div className="center-transform" 
                style={{
                    display: 'flex',
                  flexDirection: 'row',
                  zIndex: '100',
                  fontSize: '2em'}}>
              <i className="far fa-check-circle" 
                  style={{margin: 'auto', fontSize: '2em', color: 'green'}}/>
            </div>
    }
  }
}