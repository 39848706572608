import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import EditableTextField from "./EditableTextField";
import './table.css';
import BackgroundImageSelectorCell from "./BackgroundImageSelectorCell";
import PreviewCell from "./PreviewCell";
import DeleteCell from "./DeleteCell";
import ActionsCell from "./ActionsCell";
import DropDownSelectorCell from "./DropDownSelectorCell";
import { renderOptions } from "../../editor/helpers/Helpers";

export default class Table extends Component {
  constructor(props) {
    super(props);
  }

  handleCellUpdated = (id, index, column, data) => {
    this.props.handleCellUpdated(id, index, column, data);
  }

  handlePreviewRow = (cellInfo) => {
    this.props.handlePreviewRow(cellInfo);
  }

  handleEditRow = (id) => {
    this.props.handleEditRow(id);
  }

  handleImageRemoved = (id) => {
    this.props.handleDeleteBackgroundImage(id);
  }

  handleDelete = (id) => {
    this.props.handleDeleteVariableDataRow(id);
  }

  renderEditableTextField = (cellInfo) => {
    return (
      <EditableTextField cellInfo={cellInfo} handleCellUpdated={this.handleCellUpdated} />
    );
  }


  renderBackgroundImageSelector = (cellInfo) => {
    return <BackgroundImageSelectorCell
      cellInfo={cellInfo}
      includeSvgs={false}
      imageFolders={this.props.imageFolders}
      handleImageRemoved={this.handleImageRemoved}
      onImageSelected={this.handleCellUpdated} />
  }

  renderQuantitySelector = (cellInfo) => {
    return <DropDownSelectorCell
      cellInfo={cellInfo}
      handleOnChange={this.handleCellUpdated}>
      {renderOptions(1, 40)}
    </DropDownSelectorCell>
  }

  renderPreviewCell = (cellInfo) => {
    return <PreviewCell cellInfo={cellInfo}
      handleOnClick={this.handlePreviewRow}>
    </PreviewCell>
  }

  renderDeleteRowCell = (cellInfo) => {
    return <DeleteCell cellInfo={cellInfo}
      handleOnClick={this.handleDelete}>
    </DeleteCell>
  }

  renderActionsRowCell = (cellInfo) => {
    return <ActionsCell cellInfo={cellInfo}
      handleDelete={this.handleDelete}
      handlePreview={this.handlePreviewRow}
      handleEditRow={this.handleEditRow} />
  }

  render() {
    var columns = [];
    columns.push(Object.assign({
      Header: 'Quantity',
      accessor: 'quantity',
      maxWidth: 100,
      Cell: this.renderQuantitySelector
    }))

    columns = columns.concat(this.props.columns.map(column => Object.assign({ ...column }, { Cell: this.renderEditableTextField })))

    columns.push(Object.assign({
      Header: 'Background Image',
      accessor: 'backgroundImage',
      Cell: this.renderBackgroundImageSelector
    }))

    columns.push(Object.assign({
      Header: 'Actions',
      accessor: 'actions',
      maxWidth: 100,
      Cell: this.renderActionsRowCell
    }))

    return (
      <ReactTable
        getTdProps={(state, rowInfo, column, instance) => ({
          style: {
            //  cursor: 'pointer',
            overflow: 'visible'
          }
        })}
        getTbodyProps={() => ({
          style: {
            overflow: 'visible'
          }
        })}
        getTableProps={() => ({
          style: {
            overflow: 'visible'
          }
        })}
        // noDataText={null}
        NoDataComponent={() => null}
        data={this.props.tableData}
        columns={columns}
        minRows={this.props.tableData.length}
        showPagination={false}
        sortable={false}
        className="-striped -highlight"
      />
    );
  }
}