import React, { Component } from "react";
import "react-table/react-table.css";
import BackgroundImageSelector from "../../editor/BackgroundImageSelector";

export default class BackgroundImageSelectorCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.cellInfo.value?this.props.cellInfo.value:'',
            componentId: this.props.cellInfo.original.id
        }
    }

    onImageSelected = (image) => {
        if (this.props.onImageSelected) {
            this.props.onImageSelected(this.state.componentId, this.props.cellInfo.index, this.props.cellInfo.column.id, image);
        }
    }

    deleteBackgroundImage = () => {
        if(this.props.handleImageRemoved){
            this.props.handleImageRemoved(this.props.cellInfo.original.id)
        }
    }

    render() {
        return (<BackgroundImageSelector
            imageFolders={this.props.imageFolders}
            includeSvgs={this.props.includeSvgs}
            backgroundImage={this.props.cellInfo.value}
            onImageRemoved={this.deleteBackgroundImage}
            onImageSelected={this.onImageSelected}
        />)
    }
}