import React, { Component } from "react";
import PropTypes from 'prop-types';
import FontOption from './FontOption';

const fontOptions = [
    'Arial',
    'Courier New',
    'Fredericka the Great',
    'Georgia',
    'Impact',
    'Indie Flower',
    'Lucida Console',
    'Nanum Brush Script',
    'Rye',
    'Schoolbell',
    'Tahoma',
    'Times New Roman',
    'Vast Shadow',
    'Verdana'
];

export default class FontSelector extends Component {

    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    stopPropagation = (event) => {
        event.stopPropagation();
    };

    onChange = (font) => {
        this.props.onChange(font);
    }


    openPlaceholderDropdown = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        return (<div onClick={this.openPlaceholderDropdown} className="rdw-block-wrapper" aria-label="rdw-block-control">
            <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown">
                <div className="rdw-dropdown-selectedtext" title="Placeholders">
                    <span>{this.props.currentState.fontFamily?this.props.currentState.fontFamily:'Font'}</span>
                    <div className={`rdw-dropdown-caretto${this.state.open ? "close" : "open"}`}></div>
                </div>
                <ul className={`rdw-dropdown-optionwrapper ${this.state.open ? "" : "placeholder-ul"}`}>
                    {this.state.open ? fontOptions.map(font => (
                        <FontOption key={font} font={font} onChange={this.onChange} />
                    )) : null}
                </ul>
            </div>
        </div>)
    }
}

