import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from './SignOut';
import * as routes from '../constants/routes';
import AuthUserContext from '../components/AuthUserContext'
import {auth} from "../firebase";

const Navigation = () =>
    <AuthUserContext.Consumer>
      {authUser => authUser
          ? <NavigationAuth />
          : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>

const NavigationAuth = () =>
    <ul className='menu-items' >
      <li><Link className="menu-item" to={routes.LANDING}>Home</Link></li>
      <li><Link className="menu-item" to={routes.ABOUT}>About</Link></li>
      <li><Link className="menu-item" to={routes.ACCOUNT}>Account</Link></li>
      <li><Link className="menu-item" to={routes.PROJECTS}>My Games</Link></li>
      <li><Link className="menu-item" to={routes.LANDING} onClick={auth.doSignOut}>Sign Out</Link></li>
    </ul>

const NavigationNonAuth = () =>
    <ul className='menu-items' >
      <li><Link className="menu-item" to={routes.LANDING}>Home</Link></li>
      <li><Link className="menu-item" to={routes.ABOUT}>About</Link></li>
      <li><Link className="menu-item" to={routes.SIGN_IN}>Sign In</Link></li>
    </ul>

export default Navigation;