export const ComponentTypes = {
    card : {
      name: 'Card'
    },
    token : {
      name: 'Token'
    },
    tile : {
      name: 'Tile'
    }
  };