import React, {Component} from 'react';
import withAuthorization from "../components/withAuthorization";

class MyGames extends Component{


    render(){
        return <div>
            <h2>My Games</h2>
        </div>
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(MyGames);