import React from 'react';
import './Account.css';
import AuthUserContext from './AuthUserContext';
import PasswordChangeForm from '../header/PasswordChange';
import withAuthorization from './withAuthorization';

const AccountPage = () =>
  <AuthUserContext.Consumer>
    {authUser => 
      <div className="account_page">
        <div className="account_content">
          <h1 className="form-item">Account: {authUser.displayName}</h1>
          <PasswordChangeFormCheck authUser={authUser}/>
        </div>
      </div>
    }
  </AuthUserContext.Consumer>

const PasswordChangeFormCheck = ({authUser}) => {
  if(authUser.providerData[0].providerId === 'password'){
    return <PasswordChangeForm />
  }else{
    return <div/>
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AccountPage);
