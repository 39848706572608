import React, { useState } from 'react';

export function ExportPanel({ projectId, collectionId, componentId, rowNumber, handleExport, componentName, handleClose }) {

    const [showPanel, setShowPanel] = useState(true);
    const [dpi, setDpi] = useState(1);
    const [fileType, setFileType] = useState("jpeg");
    const [name, setName] = useState(componentName);

    const handleDpi = (event) => {
        setDpi(event.target.value);
    }

    const handleFileType = (event) => {
        setFileType(event.target.value);
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleDownloadPressed = () => {
        handleExport(dpi, fileType, name)
    }

    if (showPanel) {
        return (
            <div className="modal">
                <div className="modal-content">
                    {/* <span class="close">&times;</span> */}
                    <div className="close-modal" onClick={handleClose}>X</div>
                    <div style={{ float: 'center', padding: '10px' }}>
                        <div className="editor-field ">
                            <label className="editor-label">Name: </label>
                            <input
                                className="editor-input"
                                value={name}
                                onChange={handleNameChange}
                            />
                        </div>
                        <div className="editor-field">
                            <label className="editor-label">Dpi: </label>
                            <select
                                className="editor-selector"
                                id="dpi-selector"
                                value={dpi}
                                onChange={handleDpi}>
                                <option value={1} key={1}>72</option>
                                <option value={2} key={2}>144</option>
                                <option value={4.2} key={4.2}>300</option>
                            </select>
                        </div>
                        <div className="editor-field">
                            <label className="editor-label">File Type: </label>
                            <select
                                className="editor-selector"
                                id="file-type-selector"
                                value={fileType}
                                onChange={handleFileType}
                            >
                                <option value={"jpeg"} key={"jpeg"}>JPEG</option>
                                <option value={"png"} key={"png"}>PNG</option>
                            </select>
                        </div>
                    </div>
                    <button type="button" className="button download-button" onClick={handleDownloadPressed}>Download</button>

                </div>
            </div>
        )
    } else {
        return null
    }
}